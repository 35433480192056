import React, {useEffect, useState} from 'react';
import {Grid, Typography} from "@mui/material";
import {useStyles} from "./style";
import {useDispatch, useSelector} from "react-redux";
import {setTimeTable} from "../../../store/actions/RoutesActions/createRoutesActions";
import {setScheduleButton} from "../../../store/actions/RoutesActions/routesScheduleActions";
import TextField from "@mui/material/TextField";
import {setActiveButton} from "../../../store/actions/RoutesActions/routesActions";
import TimeField from "../../RoutesTabPanels/ScheduleTab/TimeField";
import CreateTimeField from "./createTimeField";


const CreateScheduleTab = () => {
    const [value, setValue] = useState([])
    const [index, setIndex] = useState([])
    const classes = useStyles();
    const stop = useSelector((state) => state.createRoutes.saveStore)
    const dispatch = useDispatch()

    const onSubmit = () => {
        dispatch(setTimeTable(+value))
        // dispatch(setTimeTable({'val': value,'idx': index}))
    }

    // const onChange = () => {
    //     dispatch(setActiveButton())
    //
    // }

    const setKey = (e) => {
        e.preventDefault()
        setValue(e.target.value)
        // dispatch(setScheduleButton())
    }

    return (
        <Grid container sx={{overflow: "auto", height: "80vh"}}>
            <Grid item width={"28%"} sx={{marginRight:"20px",}} >
                <div
                    style={{
                        margin:"5px 0",
                        borderBottom:"1px solid lightgrey",
                        color:"gray",
                        backgroundColor:'whitesmoke',
                        padding:"10px"}}>
                    <Typography variant={"body1"}>Количество остановок: {stop.length}</Typography>
                </div>
                {stop && stop.map((stops, index) => (
                    <div className={classes.prettyStopRow} key={index}>
                        <div className={classes.left}>
                            <div className={classes.index}>
                                {index + 1}
                            </div>
                            <div className={`${classes.prettyBorder} ${index === 0 && classes.first}`}>
                                <div className={`${classes.circle} ${index === 0 && classes.first}`}></div>
                            </div>
                        </div>
                        <div className={classes.right}>
                            {stops.n}
                            <p><i style={{fontSize: "12px"}}>{stops.id.d}</i></p>
                        </div>
                    </div>
                ))}
            </Grid>
            <Grid item flexGrow={1}>
                <div
                    style={{
                        margin:"5px 0",
                        borderBottom:"1px solid lightgrey",
                        color:"gray",
                        backgroundColor:'whitesmoke',
                        padding:"10px"}}>
                    <Typography variant={"body1"}>Заполните каждую ячейку</Typography>
                </div>
                {stop.map((time, idx) => (
                        <div style={{borderBottom: "1px solid lightgrey", height:"65px", padding:"5px"}}>
                                        <CreateTimeField time={time} id={idx}/>
                        </div>
                ))}
            </Grid>
        </Grid>
    );
};

export default CreateScheduleTab;