import React,{useEffect} from 'react';
import {Backdrop, Box, Button, Card, CardContent, Fade, Modal} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  cancelRouteStop,
  changedRouteStop, cleanTemp, putRouteRequest, putRouteStops,
  saveChangeStop, setTemp,
  stopChangedStop,
} from '../../../store/actions/RoutesActions/routesActions';
import { updateRoutesStop } from '../../../store/sagas/routesSagas';
import AutocompleteChange from './AutocompleteChange';
import AutocompleteAddRoutes from './AutocompleteAddRoutes';
import {
  cleanSelectedStop,
  showAutocomplete,
} from '../../../store/actions/RoutesActions/routesActions';

const style = {
  position: 'absolute',
  top: '29%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  height: '350px',
  backgroundColor: 'background.paper',
  p: 2,
};

const ChangeModal = (props) => {
  const { change, onClose, name, ids, cord } = props;
  const { id } = useParams();
  const dispatch = useDispatch();
  const single = useSelector((state) => state.routes.singleRoutes);
  const item = useSelector((state) => state.routes.selectedStop);
  const data = useSelector((state) => state.routes.changeStop);
  const show = useSelector((state) => state.routes.isAutocomplete);
  const send = useSelector((state) => state.routes.temp);
  // console.log('s', send)
  // console.log('data save', data)

  useEffect(() => {
    dispatch(cleanSelectedStop());
    dispatch(showAutocomplete());
    dispatch(cancelRouteStop(single.st.map(i => i.id)))
  }, []);

  useEffect(() => {
    dispatch(cleanTemp())
  },[])

  useEffect(() => {
    if (item.length > 0) {
      dispatch(changedRouteStop({ ids, item }));
    }
    dispatch(saveChangeStop())
    // setTimeout(() => {
    //   dispatch(saveChangeStop());
    // },1500)
  }, [item]);

  const handleChange = async() => {
    // const data = await saveStop()
    dispatch(putRouteRequest({ data, id }));
    onClose();
  };

  // const saveStop = () => {
  //   dispatch(saveChangeStop());
  //   dispatch(setTemp())
  //   return data
  // }

  const closeModal = () => {
    dispatch(cancelRouteStop(single.st.map(i => i.id)))
    onClose();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={change}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={change}>
        <Card sx={style} variant="outlined">
          <CardContent>
            <Typography style={{ marginBottom: '0px' }}>
              Заменить остановку
            </Typography>
            <div style={{ marginBottom: '13px' }}>
              <Typography style={{ marginBottom: '0px', fontWeight: 'bold' }}>
                {name}
              </Typography>
              <p>
                <i style={{ fontSize: '12px' }}>
                  {cord[0].x}, {cord[0].y}
                </i>
              </p>
            </div>
          </CardContent>

          <Box sx={{ marginBottom: 5 }}>
            {show && <AutocompleteChange props={props} />}
            {item && (
              <Box sx={{ marginLeft: '12px' }}>
                <Typography style={{ marginBottom: '0px' }}>
                  На новую остановку
                </Typography>
                <Typography style={{ marginBottom: '2px', fontWeight: 'bold' }}>
                  {item[0] && item[0].n}
                </Typography>
                <p>
                  <i style={{ fontSize: '12px' }}>
                    {item[0] && item[0].p[0].x},{item[0] && item[0].p[0].y}
                  </i>
                </p>
              </Box>
            )}
          </Box>
          <Box sx={{ pl: 8 }}>
            <Button
              color="primary"
              onClick={closeModal}
              variant="outlined"
              sx={{ mr: '8px' }}
            >
              Отменить
            </Button>
            <Button color="primary" onClick={handleChange} variant="outlined">
              Сохранить
            </Button>
          </Box>
        </Card>
      </Fade>
    </Modal>
  );
};

export default ChangeModal;