import ridesSlice from "../../slices/RidesSlice";

export const {
    fetchRides,
    clearRides,
    setStatistic,
    setDelayed,
    setOutrun,
    setRidesByID,
    switchGeneral,
    switchSingle,
    singleOutRun,
    singleDelayed
} = ridesSlice.actions;