import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Fade,
  Modal,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AutocompleteAddRoutes from './AutocompleteAddRoutes';
import { ColorActionButton, ColorButton } from './style';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  updateRoutesStop,
  updateTimeTable,
} from '../../../store/sagas/routesSagas';
import {
  addRouteStop,
  changedRouteStop,
  deletedRoutesSchedule,
  deleteRouteStop,
  saveAddStop,
  saveChangeStop,
  stopChangedStop,
  showAutocomplete,
  putRouteStops,
  cancelRouteStop,
  putRouteRequest,
  addRoutesSchedule,
  cleanTemp,
  cleanPolyline, addConfirm,
} from '../../../store/actions/RoutesActions/routesActions';
import { cleanSelectedStop } from '../../../store/actions/RoutesActions/routesActions';

const style = {
  position: 'absolute',
  top: '29%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  height: '350px',
  backgroundColor: 'background.paper',
  p: 2,
};

const AddModal = (props) => {
  const { open, onClose, name, ids, cord } = props;
  const { id } = useParams();
  const dispatch = useDispatch();
  const single = useSelector((state) => state.routes.singleRoutes);
  const item = useSelector((state) => state.routes.selectedStop);
  const show = useSelector((state) => state.routes.isAutocomplete);
  const data = useSelector((state) => state.routes.addStop);
  // const [selected, setSelected] = useState(item);

  useEffect(() => {
    dispatch(cleanSelectedStop());
    dispatch(showAutocomplete());
    dispatch(cancelRouteStop(single.st.map((i) => i.id)));
  }, []);

  useEffect(() => {
    dispatch(cleanTemp());
  }, []);

  // useEffect(() => {
  //   if (item.length > 0) {
  //     alert('hi')
  //     dispatch(addRouteStop({ ids, item }));
  //     dispatch(addRoutesSchedule({ ids }));
  //   }
  //   setTimeout(() => {
  //     dispatch(saveAddStop());
  //   }, 5000);
  // }, [item]);

  // useEffect(() => {
  //   if (data.length > 0) {
  //
  //     alert(data);
  //   }
  //   alert(single);
  // }, [data]);
  console.log('single', single);

  const handleChange = () => {
    dispatch(addConfirm())
    onClose()
    // dispatch(addRouteStop())
    dispatch(saveAddStop())
  };

  const closeModal = () => {
    dispatch(cancelRouteStop(single.st.map((i) => i.id)));
    onClose();
  };

  // useEffect(() => {
  //   dispatch(updateRoutesStop({ id, data }));
  //   //   dispatch(deletedRoutesSchedule(index))
  // }, [data]);

  //   useEffect(() => {
  //     //   dispatch(addRouteStop({ids, route:single.st, item}))
  //     console.log(item);
  //     setSelected(item);
  //   }, [item]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style} variant="outlined">
          <CardContent>
            <Typography style={{ marginBottom: '0px' }}>
              Добавить остановку после
            </Typography>
            <div style={{ marginBottom: '13px' }}>
              <Typography style={{ marginBottom: '0px', fontWeight: 'bold' }}>
                {name}
              </Typography>
              <p>
                <i style={{ fontSize: '12px' }}>
                  {cord[0].x}, {cord[0].y}
                </i>
              </p>
            </div>
          </CardContent>
          <Box sx={{ marginBottom: 5 }}>
            {show && <AutocompleteAddRoutes id={ids} />}
            {item && (
              <Box sx={{ marginLeft: '12px' }}>
                <Typography style={{ marginBottom: '0px' }}>
                  Новую остановку
                </Typography>
                <Typography style={{ marginBottom: '2px', fontWeight: 'bold' }}>
                  {item[0] && item[0].n}
                </Typography>
                <p>
                  <i style={{ fontSize: '12px' }}>
                    {item[0] && item[0].p[0].x},{item[0] && item[0].p[0].y}
                  </i>
                </p>
              </Box>
            )}
          </Box>
          <Box sx={{ pl: 8 }}>
            <Button
              color="primary"
              onClick={closeModal}
              variant="outlined"
              sx={{ mr: '8px' }}
            >
              Отменить
            </Button>
            <Button color="primary" onClick={handleChange} variant="outlined">
              Сохранить
            </Button>
          </Box>
        </Card>
      </Fade>
    </Modal>
  );
};

export default AddModal;
