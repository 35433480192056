import {combineReducers} from "redux";
import createSagaMiddleware from 'redux-saga';
import {rootSagas} from "./rootSagas";
import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import {loadFromLocalStorage} from "./localStorage";
import usersSlice from "./slices/userSlice";
import stopSlice from "./slices/stopSlice";
import axiosApi from "../axiosApi";
import routesSlice from "./slices/Routes/RoutesSlice";
import routesObjectSlice from "./slices/Routes/RoutesObjectSlice";
import routesStopsSlice from "./slices/Routes/RoutesStopseSlice";
import routesScheduleSlice from "./slices/Routes/RoutesScheduleSlice";
import createRoutesSlice from "./slices/Routes/createRoutesSlice";
import ridesSlice from "./slices/RidesSlice";
import {getCookie} from "react-use-cookie";



const rootReducer = combineReducers({
    'users': usersSlice.reducer,
    'stops':stopSlice.reducer,
    'routes': routesSlice.reducer,
    'objects': routesObjectSlice.reducer,
    'routesStops': routesStopsSlice.reducer,
    'routesSchedule': routesScheduleSlice.reducer,
    'createRoutes': createRoutesSlice.reducer,
    'rides': ridesSlice.reducer
});


const sagaMiddleware = createSagaMiddleware();

const persistedState = loadFromLocalStorage();

const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false
})

const middleware = [...customizedMiddleware, sagaMiddleware];

const store = configureStore({
    reducer: rootReducer,
    middleware,
    preloadedState: persistedState,
    devTools: true,
});

sagaMiddleware.run(rootSagas);


// store.subscribe(() => {
//     saveToLocalStorage({
//         users: {
//             ...initialState,
//             user: store.getState().users.user
//         },
//     });
// });


axiosApi.interceptors.request.use(config => {
    const csrfToken = getCookie('csrftoken');
    try {
       config.headers['X-CSRFToken'] = csrfToken;

    } catch (e) {}

    return config;
});


export default store;