import React from 'react';
import {Backdrop, Card, CardContent, Fade, Modal} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useSelector} from "react-redux";
import "./style.css"
import {useNavigate} from "react-router-dom";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {xs: 250, md: 700},
    backgroundColor: 'background.paper',
    p: 2,

}

const ModalComponent = ({open, onClose}) => {
    const navigate = useNavigate();
    const routes = useSelector(state => state.stops.stopInRoutes);
    const count = useSelector(state => state.stops.routesCountInStop);
    
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Card sx={style} variant="outlined">


                    {count && count > 0 ? (
                        <CardContent>
                            <Typography variant="overline" textAlign="center" sx={{marginBottom: "20px"}}>
                                Эта остановка задействованна в маршрутах: {count}
                            </Typography>
                            <div className={"route-box"}>

                                {routes && Object.keys(routes).map(obj => (
                                    <div className={"routes"} onClick={()=>navigate("/routes/"+routes[obj].id)}>
                                        <div className="routesNum">
                                            <Typography variant={"button"}>{routes[obj].n}</Typography>
                                        </div>
                                        <div style={{overflow: "hidden", whiteSpace: "nowrap", marginLeft: "10px"}}>
                                            {routes[obj].plan.map(stop => (
                                                <Typography variant={"caption"}
                                                            sx={{fontSize: "15px"}}>{stop}</Typography>
                                            ))}
                                        </div>

                                    </div>
                                ))}
                            </div>
                        </CardContent>
                    ) : (

                        <CardContent>
                            <Typography variant="overline" textAlign="center" sx={{marginBottom: "20px"}}>
                                Эта остановка не задействованна в доступных маршрутах
                            </Typography>

                        </CardContent>
                    )}


                </Card>
            </Fade>
        </Modal>
    );
};

export default ModalComponent;