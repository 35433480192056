import {Grid, Typography} from "@mui/material";
import CreateCheckbox from "./createCheckbox";
import {useStyles} from "./style";
import {useDispatch, useSelector} from "react-redux";
import {useDebugValue, useEffect, useState} from "react";
import {
    fetchRoutesGroupsRequest,
    fetchRoutesUnitsRequest, openAssignSaveButton, setActiveButton, setGlobalId, showUnitsCount
} from "../../../store/actions/RoutesActions/routesActions";
import {
    assignGroups, setIsCheck ,
    assignUnits, saveNewRoute, clearAssignUnits, clearIsCheck
    // fetchRoutesGroupsRequest,
    // fetchRoutesUnitsRequest, openAssignSaveButton, setActiveButton, setGlobalId, showUnitsCount
} from "../../../store/actions/RoutesActions/createRoutesActions";
import { cleanActiveButton} from "../../../store/actions/RoutesActions/routesObjectsActions";
import {useParams} from "react-router-dom";
import {setActiveSchedulle} from "../../../store/actions/RoutesActions/routesStopsActions";
import {setScheduleButton} from "../../../store/actions/RoutesActions/routesScheduleActions";


export const Group = () => {
    const dispatch = useDispatch()
    const classes = useStyles();
    const {id} = useParams()
    const label = {inputProps: {'aria-label': 'Checkbox demo'}};
    const groups = useSelector((state) => state.routes.groups)
    const units = useSelector((state) => state.routes.units)
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [list, setList] = useState([]);
    const [singleList, setSingleList] = useState([])
    const isCheck = useSelector((state) => state.createRoutes.check)
    const [isCounts, setIsCounts] = useState([]);
    // const [isCheck, setCheck] = useState([])
    const [isUnits, setIsUnits] = useState([])
    const single = useSelector((state) => state.routes.singleRoutes)

//show units change groups id
    const changeUnits = (id) => {
        const unite = units.filter(unit => unit.groupid === id)
        setIsUnits(unite)
    }

    const setActiveGroup = () => {
        // const activeGroup = []
        // // const checkGroup = groups.map(i => i.id)
        // // if(single.ug[0].id === list[1].id){
        // // if(single.ug.length > 0){
        //     activeGroup.push(single.ug[0].id)
        //     handleSelectAll(single.ug[0].id)
        // // }
        //     dispatch(setIsCheck(activeGroup))

        // }
    }

        useEffect(() => {
        dispatch(fetchRoutesGroupsRequest())
        dispatch(fetchRoutesUnitsRequest())
    },[])

    // useEffect(() => {
    //     const unite = units.filter(unit => unit.groupid === 1)
    //     setIsUnits(unite)
    // },[])

    // useEffect(() => {
    //     setList(groups);
    // }, []);

    // useEffect(() => {
    //     setActiveGroup()
    // },[id])

    const handleSelectAll = id => {
        const groupes = groups.filter(group => group.id === id).map(item => item.id)
        const unites = units.filter(unit => unit.groupid == id)
        const asUnits = unites.map(item => item.id)
        dispatch(assignUnits(asUnits))
        dispatch(assignGroups(groupes))
        // dispatch(assignUnitsToRouteRequest(unites))
        setIsUnits(unites)
        // dispatch(cleanActiveButton())
        // dispatch(setScheduleButton())
        // dispatch(openAssignSaveButton())
        dispatch(setIsCheck(groupes))
        if(isCheck.includes(id))
            dispatch(clearIsCheck(id))
        if (isCheck.includes(id)) {
            dispatch(clearAssignUnits(unites))
            setIsCheckAll(false);
            // clearIsCheckAll();
        } else {
            setIsCheckAll(true);
        }
        // if (isCheck) {
        //     // setIsCheck(false);
        //     // setCheck(isCheck.filter(i => i !== id))
        //     clearIsCheck(id);
        // } else {
        //     setIsCheck(!isCheckAll);
        // }
        dispatch(saveNewRoute())
    };

    return (
        <div style={{display:"block", padding:"0 10px" }}>
            <div style={{display:"flex"}}>
                <Grid item sx={{width:'30%', color: 'darkgray', padding: "10px", backgroundColor: "whitesmoke"}}>
                    <Typography variant={'subtitle1'} sx={{fontSize: "14px"}}>Группы обьектов: {groups && groups.length}</Typography>
                </Grid>
                <Grid item sx={{width:'75%', color: 'darkgray', padding: "10px", backgroundColor: "whitesmoke"}}>
                    {isCounts && <Typography variant={'subtitle1'} sx={{fontSize: "14px"}}>Найдено
                        элементов: {isUnits && isUnits.length}</Typography>}
                </Grid>
            </div>
            <div >
                <div className={classes.listBox}>
                    <Grid
                        item
                        // width={'20%'}
                        sx={{ overflow: 'auto', height: '80vh', backgroundColor: 'whitesmoke' }}
                    >
                        {groups && groups.map((group, index) => (
                            <div key={group.id}>
                                <div className={classes.prettyStopRow}>
                                    <div className={classes.left}>
                                        <div
                                            className={`${classes.prettyBorder} ${
                                                index === 0 && classes.first
                                            }`}
                                        >
                                            <div
                                                className={`${classes.circle} ${
                                                    index === 0 && classes.first
                                                }`}
                                            ></div>
                                        </div>
                                    </div>
                                    <div className={classes.right}>
                                        <div className={classes.row}>
                                            <CreateCheckbox
                                                type="checkbox"
                                                name={group.name}
                                                id={group.id}
                                                handleClick={() => handleSelectAll(group.id)}
                                                isChecked={isCheck.includes(group.id)}
                                                //    const single = useSelector((state) => state.routes.singleRoutes)
                                            />
                                            <Typography variant={"subtitle2"} className={classes.name}
                                                        onClick={() => changeUnits(group.name)}>
                                                {group.name} </Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Grid>
                    <Grid className={classes.horizontalList}>
                        {isUnits && isUnits.map( (unit, index) => (

                            <div className={classes.row} key={unit.id}>
                                <CreateCheckbox
                                    key={unit.groupid}
                                    type="checkbox"
                                    name={unit.name}
                                    id={unit.groupid}
                                    // handleClick={handleClick}
                                    isChecked={isCheckAll}
                                />
                                <Typography variant={"subtitle2"} className={classes.name}>{unit.name} </Typography>
                            </div>
                        ))}
                    </Grid>
                </div>
            </div>
        </div>
    )
}