/*global google*/
import React, {useEffect} from 'react';
import { Grid } from '@mui/material';
import {  useStyles } from './style';
import {useDispatch, useSelector} from 'react-redux';
import {
    useLoadScript
} from '@react-google-maps/api';
import Map from "./createMap";
import Preloader from "../../UI/Preloader/Preloader";
import {sliceFistStop} from "../../../store/actions/RoutesActions/createRoutesActions";

const CreateStopsTab = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const stops = useSelector((state) => state.createRoutes.saveStore)
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyD8VJHZ2vIQNxAZZ1hf0vKnEa3KjmXM1Pg',
    });

    useEffect(() => {
        dispatch(sliceFistStop())
    },[])


    return (
        <Grid container>
            <Grid
                item
                width={'30%'}
                sx={{ overflow: 'auto', height: '80vh', backgroundColor: 'whitesmoke' }}
            >
                {!isLoaded ? (
                    <Preloader />
                ) : (
                    <div>
                        {stops && stops.map((stops, index) => (
                            <div className={classes.prettyStopRow} key={index}>
                                <div className={classes.left}>
                                    <div className={classes.index}>{index + 1}</div>
                                    <div
                                        className={`${classes.prettyBorder} ${
                                            index === 0 && classes.first
                                        }`}
                                    >
                                        <div
                                            className={`${classes.circle} ${
                                                index === 0 && classes.first
                                            }`}
                                        ></div>
                                    </div>
                                </div>
                                <div className={classes.right}>
                                    {stops.n}
                                    <p className={classes.cordination}>
                                        {stops.d}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                    )}
            </Grid>
            <Grid item flexGrow={1}>
                <div className={classes.container} style={{ position: 'relative' }}>
                    {stops && <Map stops={stops}
                                   zoom={12.3}
                                   center={{ lat: 40.5283, lng: 72.7985 }}
                                   googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyD8VJHZ2vIQNxAZZ1hf0vKnEa3KjmXM1Pg"
                                   loadingElement={<div style={{ height: `100%` }} />}
                                   containerElement={<div style={{ height: `80vh` }} />}
                                   mapElement={<div style={{ height: `100%` }} />}/>}
                </div>
            </Grid>
        </Grid>
    );
};

export default CreateStopsTab;
