import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import { makeStyles } from '@mui/styles';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { nanoid } from 'nanoid';
import { useDispatch, useSelector } from 'react-redux';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import {
  deleteSelectedStopRequest,
  deleteStopRequest,
  fetchSingleStopRequest,
  fetchStopInRoutesRequest,
  fetchStopsRequest,
  getStopIdToEdit,
  showCheck,
  showEditOpen,
  showOverlay,
} from '../../../store/actions/stopsActions';
import { blue, green, yellow } from '../../../colors';
import { deleteRoute } from '../../../store/sagas/routesSagas';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {
  changedRouteStop,
  fetchRoutesStopsRequest,
  saveChangeStop,
  setSelectedStop,
  hideAutocomplete, cleanSelectedStop,
} from '../../../store/actions/RoutesActions/routesActions';

const useStyles = makeStyles(() => ({
  autocomplete: {
    fontSize: '16px',
    borderBottom: '1px solid #DFDFDF',
    padding: ' 5px 10px',
    width: '80%',
    height: 'auto',
    position: 'relative',
    overflow: 'visible',
    cursor: 'pointer',
  },

  popoverBox: {
    position: 'absolute',
    top: '0',
    right: '-100px',
    border: '1px solid black',
    padding: '10px',
    zIndex: '555555',
  },
}));

const style = {
  fontSize: '17px',
  color: 'grey',
};

const AutocompleteChange = (props) => {
  const { change, onClose, name, ids } = props;
  const dispatch = useDispatch();
  const single = useSelector((state) => state.routes.singleRoutes);
  const item = useSelector((state) => state.routes.selectedStop);
  const classes = useStyles();
  const stops = useSelector((state) => state.stops.stops);
  // console.log('i', item)
  //
  // const i = item.length > 0
  //
  // useEffect(() => {
  //
  //     dispatch(changedRouteStop({ ids, item }));
  //     dispatch(saveChangeStop());
  //     debugger
  // }, [i]);
  useEffect(() => {
    dispatch(cleanSelectedStop());
  }, []);

  const handleClick = (option) => {
    dispatch(setSelectedStop(option));
    dispatch(hideAutocomplete());
    // dispatch(setSelectedStop(addElement));
  };

  return (
    <>
      {stops && (
        <Autocomplete
          id="combo-box-demo"
          open={true}
          options={stops}
          style={{
            height: '80%',
            width: '100%',
          }}
          ListboxProps={{ style: { maxHeight: '61vh' } }}
          getOptionLabel={(option) =>
            `${option.n} ${option.p[0]?.x} ${option.p[0]?.y}`
          }
          renderOption={(props, option) => {
            return (
              <>
                <div
                  className={classes.autocomplete}
                  // onClick={(e) => onStopClick(e, option.id)}
                  key={nanoid()}
                  id={option.id}
                >
                  <div onClick={() => handleClick(option)}>
                    {option.n}
                    <p>
                      <i style={{ fontSize: '12px' }}>
                        {option.p[0].x} , {option.p[0].y}
                      </i>
                    </p>
                  </div>
                </div>
              </>
            );
          }}
          sx={{}}
          renderInput={(params) => <TextField {...params} label="Поиск" />}
        />
      )}
    </>
  );
};

export default AutocompleteChange;
