import React, {useEffect, useState} from 'react';
import {Grid, InputAdornment, TextField} from "@mui/material";
import Badge from '@mui/material/Badge';
import {styled} from '@mui/material/styles';
import {makeStyles} from "@mui/styles";
import {
    changeTransportType,
    fetchAllStopsCountRequest,
    fetchStopsForBusRequest, fetchStopsForTaxiRequest,
    fetchStopsForTrolleybusRequest,
    fetchStopsRequest, fetchUnknownStopsRequest
} from "../../store/actions/stopsActions";
import {useDispatch, useSelector} from "react-redux";
import './style.css';
import AddIcon from '@mui/icons-material/Add';
import {useSearchParams} from "react-router-dom";
import AddPopup from "./addPopup";
import {searchReturnAll, searchRoutes} from "../../store/actions/RoutesActions/routesActions";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";
import {cleanSaveData, setAddPopup} from "../../store/actions/RoutesActions/createRoutesActions";


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -15,
    top: 10,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    backgroundColor: '#166767',
    width: '30px',
  },
}));

const useStyles = makeStyles(() => ({
  transportTypesBtn: {
    textTransform: 'capitalize',
    color: '#166767',
    padding: '8px',
    '&:hover': {
      backgroundColor: '#98B8B8',
    },
  },

  active: {
    backgroundColor: '#98B8B8',
  },
}));

const TransportTypes = () => {
  const dispatch = useDispatch();
  const addPopup = useSelector(((state) => state.createRoutes.addPopup))
  const busStops = useSelector((state) => state.stops.stops);
  const showAddSearch = useSelector((state) => state.routes.showAddSearch);
  const allStops = useSelector((state) => state.stops.allStops);
  const stopsForBus = useSelector((state) => state.stops.stopsForBus);
  const stopsForTrolleybus = useSelector(
    (state) => state.stops.stopsForTrolleybus
  );
  const stopsForTaxi = useSelector((state) => state.stops.stopsForTaxi);
  const unknownStops = useSelector((state) => state.stops.unknownStops);
  const [search, setSearch] = useState('');
  const [routes, setRoutes] = useState(null);

  useEffect(() => {
    dispatch(fetchStopsRequest());
  }, []);

  useEffect(() => {
    setRoutes(search);
  }, [search]);

  useEffect(() => {
    dispatch(fetchAllStopsCountRequest(`?cnt_tp=-1`));
    dispatch(fetchStopsForBusRequest(`?cnt_tp=1`));
    dispatch(fetchStopsForTrolleybusRequest(`?cnt_tp=2`));
    dispatch(fetchStopsForTaxiRequest(`?cnt_tp=3`));
    dispatch(fetchUnknownStopsRequest(`?cnt_tp=0`));
  }, [busStops]);

  const onTypeClick = (id, type) => {
    let typeOfTransport;
    if (id === 'bus') {
      typeOfTransport = 'Автобусы';
    } else if (id === 'trolleybus') {
      typeOfTransport = 'Троллейбусы';
    } else if (id === 'taxi') {
      typeOfTransport = 'Маршрутные такси';
    } else if (id === 'unknown') {
      typeOfTransport = 'Без типа';
    } else if (id === 'all') {
      typeOfTransport = 'Все';
    }

    dispatch(changeTransportType(typeOfTransport));

    if (type === 'all') {
      dispatch(fetchStopsRequest());
    } else {
      dispatch(fetchStopsRequest(`?tp=${type}`));
    }
  };

  const searchSubmit = (search) => {
    if (search == '') {
      dispatch(searchReturnAll());
      // dispatch(fetchRidesRequest())
      // dispatch(switchGeneral())
    } else {
      dispatch(searchRoutes(search));
    }
  };


  return (
    <div>
      <Grid container style={{ backgroundColor: 'whitesmoke', padding: '5px' }}>
        {/*<Grid container>*/}
        <Grid item style={{ marginRight: '50px' }}>
          <button
            className="transportTypesBtn"
            id={'all'}
            onClick={(e) => onTypeClick('all', 'all')}
          >
            <StyledBadge
              badgeContent={allStops && allStops.count}
              color="primary"
              max={10000}
              showZero
            >
              Все
            </StyledBadge>
          </button>
        </Grid>
        <Grid item style={{ marginRight: '50px' }}>
          <button
            className="transportTypesBtn"
            id={'bus'}
            onClick={() => onTypeClick('bus', 1)}
          >
            <StyledBadge
              badgeContent={stopsForBus && stopsForBus.count}
              color="primary"
              max={10000}
              showZero
            >
              Автобусы
            </StyledBadge>
          </button>
        </Grid>
        <Grid item style={{ marginRight: '50px' }}>
          <button
            className="transportTypesBtn"
            id="trolleybus"
            onClick={() => onTypeClick('trolleybus', 2)}
          >
            <StyledBadge
              badgeContent={stopsForTrolleybus && stopsForTrolleybus.count}
              color="primary"
              showZero
              max={10000}
            >
              Троллейбусы
            </StyledBadge>
          </button>
        </Grid>
        <Grid item style={{ marginRight: '50px' }}>
          <button
            className="transportTypesBtn"
            id={'taxi'}
            onClick={() => onTypeClick('taxi', 3)}
          >
            <StyledBadge
              badgeContent={stopsForTaxi && stopsForTaxi.count}
              color="primary"
              showZero
              max={10000}
            >
              Маршрутные такси
            </StyledBadge>
          </button>
        </Grid>
        <Grid item style={{ marginRight: '50px' }}>
          <button
            className="transportTypesBtn"
            id={'unknown'}
            onClick={() => onTypeClick('unknown', 0)}
          >
            <StyledBadge
              badgeContent={unknownStops && unknownStops.count}
              color="primary"
              showZero
              max={10000}
            >
              Без типа
            </StyledBadge>
          </button>
        </Grid>
        {showAddSearch && (
          <>
            <Grid
              style={{
                marginLeft: '300px',
                paddingTop: '10px',
                cursor: 'pointer',
              }}
            >
              <Tooltip title="Добавить маршрут" arrow>
                <AddIcon onClick={() => dispatch(setAddPopup())} />
              </Tooltip>
            </Grid>
            <Grid style={{ marginLeft: '15px', cursor: 'pointer' }}>
              <input
                type={'number'}
                placeholder={'Поиск по маршруту'}
                style={{
                  width: '250px',
                  height: '40px',
                  padding: '5px',
                  border: '1px solid grey',
                }}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && searchSubmit(search)}
                // InputProps={{
                //     startAdornment: (
                //         <InputAdornment position="start">
                //             <SearchIcon />
                //         </InputAdornment>
                //     ),
                // }}
              />
            </Grid>
          </>
        )}
      </Grid>
      {addPopup && (
        <AddPopup add={addPopup} stops={busStops} />
      )}
    </div>
  );
};

export default TransportTypes;