import React, {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import {makeStyles} from "@mui/styles";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import {Checkbox, FormControlLabel, FormGroup, Grid, IconButton, Tooltip, Typography} from "@mui/material";
import ListIcon from '@mui/icons-material/List';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import {nanoid} from "nanoid";
import {useDispatch, useSelector} from "react-redux";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import {
    deleteSelectedStopRequest,
    deleteStopRequest,
    fetchSingleStopRequest,
    fetchStopInRoutesRequest, getStopIdToEdit, showCheck, showEditOpen, showOverlay
} from "../../../store/actions/stopsActions";
import {blue, green, yellow} from "../../../colors";
import {deleteRoute} from "../../../store/sagas/routesSagas";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const useStyles = makeStyles(()=> ({
    autocomplete: {
        fontSize: "16px",
        borderBottom: "1px solid #DFDFDF",
        padding: " 5px 10px",
        width: "80%",
        position: "relative",
        overflow: "visible",
        cursor: 'pointer'
    },

    popoverBox: {
        position: "absolute",
        top: '0',
        right: '-100px',
        border: '1px solid black',
        padding: '10px',
        zIndex: "555555",
    },
}));

const style = {
    fontSize: "17px",
    color: "grey"
}

const CreateAutocompleteAddRoutes = (id) => {
    const dispatch = useDispatch()
    const classes = useStyles();
    const routes = useSelector(state => state.routes.singleRoutes);
    debugger
    const routesCount = useSelector(state => state.stops.routesCountInStop);
    const [showBtn, setShowBtn] = useState(null);
    const showCheckbox = useSelector(state => state.stops.showCheckbox)

    const [isCheck, setIsCheck] = useState([]);

    const [showRoutesInStop, setShowRoutesInStop] = useState(false)

    useEffect(() => {
       dispatch(fetchSingleStopRequest(id))
    },[])

    const onStopClick = (e, id) => {
        alert(id)
        if (showCheckbox === true) {
            e.stopPropagation();
        } else {
            dispatch(fetchSingleStopRequest(id))
            dispatch(fetchStopInRoutesRequest(id))
            if (parseInt(e.currentTarget.id) === id) {
                setShowBtn(id)
            }
        }
    }

    const handleClick = e => {
        const {id, checked} = e.target;
        alert(id)
        setIsCheck([...isCheck, parseInt(id)]);
        if (checked === false) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }
    };


    return (
        <>
            {routes && (
                <Autocomplete
                    id="combo-box-demo"
                    open={true}
                    options={[routes.st]}
                    style={{

                        width: "90%",
                        margin: "auto"
                    }}
                    ListboxProps={{style: {maxHeight: '61vh'}}}
                    // getOptionLabel={(option) => `${option.n} ${option[st].[0]?.x} ${option[0].p[0]?.y}`}
                    renderOption={(props, option) => {
                        return (
                            <>
                                <div
                                    className={classes.autocomplete}
                                    onClick={(e) => onStopClick(e, option.id.id)}
                                    key={nanoid()}
                                    id={option.id.id}
                                >
                                    {showCheckbox && (
                                        <div
                                            id={option.id.id}
                                            style={{
                                                position: "absolute",
                                                top: "0", right: "0",
                                                zIndex: "123",
                                            }}>
                                            <input
                                                type="checkbox"
                                                id={option.id}
                                                checked={isCheck.includes(option.id.id)}
                                                onChange={handleClick}
                                            />
                                        </div>
                                    )}

                                    <div>
                                        {option.n}
                                        <p><i style={{fontSize: "12px"}}>{option.p[0].x} , {option.p[0].y}</i></p>
                                        <DirectionsBusIcon
                                            sx={{
                                                color: option.tp === 2 && blue ||
                                                    option.tp === 3 && yellow ||
                                                    option.tp === 0 && blue ||
                                                    green,
                                                fontSize: "12px",
                                            }}/>
                                    </div>

                                    {option.id === showBtn && !showCheckbox && (
                                        <div style={{position: "absolute", bottom: "0", right: "0"}}>
                                            <IconButton aria-label="edit"
                                                        onClick={() => {
                                                            dispatch(getStopIdToEdit(showBtn));
                                                            dispatch(showEditOpen())
                                                        }}
                                            >
                                                <ModeEditIcon sx={style}/>
                                            </IconButton>
                                            <IconButton aria-label="delete">
                                                <DeleteIcon sx={style} onClick={() => dispatch(deleteRoute(option.id))}/>
                                            </IconButton>
                                        </div>
                                    )}
                                </div>
                            </>
                        );
                    }}
                    sx={{}}
                    renderInput={(params) => <TextField {...params} label="Поиск"/>}
                />
            )}

        </>

    );
};

export default CreateAutocompleteAddRoutes;