import React, {useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import {makeStyles} from "@mui/styles";
import Switch from '@mui/material/Switch';
import {useNavigate} from "react-router-dom";
import {singleRoute} from "../../paths";
import {useStyles} from "./style";
import {useDispatch, useSelector} from "react-redux";
import {fetchStopsRequest} from "../../store/actions/stopsActions";
import {
    cleanActiveRoute,
    fetchAllRoutesRequest,
    fetchRoutesStopsRequest, fetchRoutesGroupsRequest,
    fetchRoutesUnitsRequest,
    setActiveRoute, setDeletePopup, showAddandSearch, routesGroupsAndUnitsClean
} from "../../store/actions/RoutesActions/routesActions";
import {Link} from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import {fetchRoutesUnitsSagas, putTurnRoute} from "../../store/sagas/routesSagas";
import DayJS from "react-dayjs/source";
import zIndex from "@mui/material/styles/zIndex";
import DeletePopup from "./deletePopup";
import Tooltip from "@mui/material/Tooltip";
import {
    clearSingleRoutes
} from "../../store/actions/RoutesActions/createRoutesActions";
import {cleanCheck} from "../../store/actions/RoutesActions/routesObjectsActions";
import Preloader from "../UI/Preloader/Preloader";

const AutocompleteRoutes = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const search = useSelector(state => state.routes.searched);
    const checked = useSelector((state) => state.routes.activeRoute)
    const routs = useSelector((state) => state.routes.stops);
    const [active, setActive] = useState([])
    const [selectedRoute, setSelectedRoute] = useState([])
    const isDeletePopup = useSelector((state) => state.routes.deletePopup)
    const [selectedID, setSelectedID] = useState([])
    const [routes, setRoutes] = useState(null)

    useEffect(() => {
        dispatch(routesGroupsAndUnitsClean())
        dispatch(cleanCheck())
        dispatch(clearSingleRoutes())
    },[])

    const handleClose = () => dispatch(setDeletePopup());

    useEffect(() => {
        dispatch(fetchRoutesStopsRequest());
        dispatch(fetchStopsRequest());
        dispatch(showAddandSearch())
        dispatch(fetchRoutesGroupsRequest())
        dispatch(fetchRoutesUnitsRequest())
    }, []);


    useEffect(() => {
        setRoutes(routs)
    },[routs])

    useEffect(() => {
        setRoutes(search)
    },[search])

    const handleDelete = (item) => {
        dispatch(setDeletePopup())
      setSelectedID(item.id);
      setSelectedRoute(item);
    };

    useEffect(() => {
      const active = routs.filter((i) => i.a === true);
      dispatch(setActiveRoute(active.map((i) => i.id)));
    }, [routs]);
    // const r = streets.map(item => item.st.map(i => i.st_rt_id))

    const handleChange = (item) => {
      const id = item.id;
      const active = !item.a;
      dispatch(putTurnRoute({ id, active }));
      if (checked.includes(item.id)) {
        dispatch(cleanActiveRoute(item.id));
      } else {
        dispatch(setActiveRoute([item.id]));
      }
    };

    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    return (
      <>
          {!routes ? (
          <Preloader />
      ) : (
          routes.map((item) => (
            <div className={classes.autocomplete} key={item.id}>
              <Link to={`${item.id}`} key={item.id} className={classes.routes}>
                <div
                  style={{
                    display: 'flex',
                    width: '7.5%',
                    justifyContent: 'start',
                    alignItems: 'center',
                  }}
                >
                  <DirectionsBusIcon
                    sx={{
                      color: 'white',
                      fontSize: '18px',
                      borderRadius: '50%',
                      backgroundColor: 'green',
                    }}
                  />
                  <div
                    style={{
                      border: '1px solid lightgrey',
                      textAlign: 'center',
                      marginLeft: '15px',
                      marginRight: '45px',
                      padding: '3px',
                      zIndex: '0',
                    }}
                  >
                    {item.n}
                  </div>
                </div>
                <span style={{ display: 'flex' }}>
                  {item.st.slice(0, 6).map((i) => (
                    <p
                      style={{
                        fontSize: '18px',
                        paddingLeft: '16px',
                        zIndex: '0',
                      }}
                    >
                      {i.i < 5
                        ? i.id.n.length > 20
                          ? i.id.n.slice(0, 17) + '...'
                          : i.id.n
                        : null}
                    </p>
                  ))}
                  ...
                </span>
              </Link>
              <div className={classes.option}>
                <div>
                  <p>Расписаний: {item.tt.length}</p>
                  <p>
                    Изменён: <DayJS format="DD-MM-YYYY">{item.tm}</DayJS>
                  </p>
                </div>
                {checked && (
                    <Tooltip title="Вкл/Выкл маршрут" arrow>
                  <Switch
                    checked={checked.includes(item.id)}
                    onClick={() => handleChange(item)}
                    onChange={() => setActive(item.a === false ? true : false)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                    </Tooltip>
                )}
                {/*<LibraryAddIcon/>*/}
                  <Tooltip title="Удалить маршрут" arrow>
                <DeleteIcon
                  style={{ width: '30px', height: '30px' }}
                  onClick={() => handleDelete(item)}
                />
                  </Tooltip>
              </div>
              {isDeletePopup && (
                <DeletePopup
                  remove={isDeletePopup}
                  onClose={handleClose}
                  name={selectedRoute}
                  id={selectedID}
                />
              )}
            </div>
          ))
          )}
      </>
    );
};

export default AutocompleteRoutes;