import {createSlice} from "@reduxjs/toolkit";

const name = 'route';

const initialState = {
  transportType: null,
  stops: [],
  activeRoute: [],
  fetchLoading: false,
  singleRoutes: [],
  saveStore: [],
  addRoute: [],
};


const routesStopsSlice = createSlice({
        name,
        initialState,
        reducers: {
            fetchRoutesStopsRequest(state) {
                state.fetchLoading = true;
            },
            fetchRoutesStopsFailure(state) {
                state.fetchLoading = false;
            },
            fetchRoutesStopsSuccess(state, action) {
                state.stops = action.payload;
                state.fetchLoading = false;
            },
            fetchRoutesGroupsRequest(state) {
                state.fetchLoading = true;
            },
            fetchRoutesGroupsFailure(state) {
                state.fetchLoading = false;
            },
            fetchRoutesGroupsSuccess(state, action) {
                state.groups = action.payload;
                state.fetchLoading = false;
            },
            fetchRoutesUnitsRequest(state) {
                state.fetchLoading = true;
            },
            fetchRoutesUnitsFailure(state) {
                state.fetchLoading = false;
            },
            fetchRoutesUnitsSuccess(state, action) {
                state.units = action.payload;
                state.allRoutes = false;
            },
            fetchSingleRoutesRequest(state) {
                state.singleRoutesLoad = true;
            },
            fetchSingleRoutesSuccess(state, action) {
                state.singleRoutes = action.payload;
                state.singleRoutesLoad = false;
            },
            fetchSingleRoutesFailure(state) {
                state.singleRoutesLoad = false;
            },
            clearSingleRoutes(state){
                state.singleRoutes = []
            },
            addRoutesStopRequest(state) {
                state.addLoading = true;
            },
            addRoutesStopSuccess(state, action) {
                state.addLoading = false;
            },
            addRoutesStopFailure(state) {
                state.addLoading = false;
            },
        }
    })

;

export default routesStopsSlice;