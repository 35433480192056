import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {
    createSetNewTime,
    createSetUpdateTime,
    fillEmptyTime
} from "../../../store/actions/RoutesActions/createRoutesActions";

const CreateTimeField = ({time, id}) => {

    // function increaseIndexToEven(index) {
    //     if (index % 2 === 0) {
    //         return index
    //     } else {
    //         return index +=3;
    //     }
    //
    // }

    // console.log("id", id)


    useEffect(() => {
        const sec = Math.max(0, id);
        const seconds = sec * 60
        console.log(seconds)
        dispatch(createSetNewTime({idx: id, time: seconds}))
    },[id])

    const formatTime = (seconds) => {
        if (isNaN(seconds)) {
            return "00:00";
        }
        const date = new Date(seconds * 1000);
        const hh = date.getUTCHours().toString();
        const mm = date.getUTCMinutes().toString().padStart(2, "0");
        if (parseInt(hh) > 0) {
            return `${hh}:${mm}`;
        }
        return `${hh}:${mm}`;
    };

    const dispatch = useDispatch()
    const [value, setValue] = React.useState(`00:0${id}`);

    // useEffect(() => {
    //     dispatch(fillEmptyTime())
    // },[])

    const onChange = (event) => {
        setValue(event.target.value);
    };

    const onBlur = (event) => {
        const val = event.target.value;
        const seconds = Math.max(0, getSecondsFromHHMMSS(val));
        dispatch(createSetNewTime({idx: id, time: seconds}))
        dispatch(createSetUpdateTime(seconds))
        setValue(formatTime(seconds));
    };

    const getSecondsFromHHMMSS = (value) => {
        const [str1, str2] = value.split(":");

        const val1 = Number(str1);
        const val2 = Number(str2);


        if (!isNaN(val1) && !isNaN(val2)) {
            return val1 * 60 * 60 + val2 * 60;
        }
        return 0;
    };

    const getSecondsFromIdx = (value) => {
        if(!isNaN(value)) {
            return value * 60
        }
    };


    return (
        <div style={{border: "1px solid lightgrey", height:"65px", width:"90px", padding:"20px 20px"}}>
            <input type="text" onChange={onChange} onBlur={onBlur} value={value} required={true}/>
        </div>
    );
};

export default CreateTimeField;