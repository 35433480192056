/* eslint-disable no-unused-expressions */
import { createSlice } from '@reduxjs/toolkit';
import {
  deleteRouteStop,
  postNewRouteRequest,
  saveChangeStop,
  setSubmit,
} from '../../actions/RoutesActions/routesActions';
import { encode } from '@googlemaps/polyline-codec';

const name = 'route';

const initialState = {
  check: [],
  transportType: null,
  stops: [],
  groups: [],
  units: [],
  assign: [],
  assignRoute: [],
  assignGroups: [],
  assignUnits: [],
  activeRoute: [],
  fetchLoading: false,
  singleRoutes: [],
  saveButton: false,
  deletePopup: false,
  pageCount: 1,
  globalID: null,
  saveSchedulle: false,
  addRoute: [],
  saveTime: [],
  routeName: null,
  saveNewRoute: null,
  searched: null,
  setUpdateTime: [],
  oldTimeTable: [],
  deletedStopRoutes: [],
  deletedStop: [],
  deletedSchedule: [],
  changeStop: [],
  changedStopRoutes: [],
  singleST: [],
  selectedStop: [],
  polyline: [],
  isAutocomplete: true,
  addStopRoutes: [],
  addStop: [],
  stopRoutes: [],
  scheduleButton: false,
  objectButton: false,
  showAddSearch: false,
  updateRoute: [],
  DSresult: [],
  isSubmit: false,
  changeButton: false,
  temp: false,
  updatetPolyline: [],
  addSchedule: [],
  stopTab: [],
  singleTemp: [],
  confirm: false,
  notFound: false,
  support: false,
  schedule:[],
  addConfirm: false
};

const routesSlice = createSlice({
  name,
  initialState,
  reducers: {
    setSupport(state) {
      state.support = true;
    },
    closeSupport(state) {
      state.support = false;
    },
    routesGroupsAndUnitsClean(state) {
      state.assignGroups = [];
      state.assignUnits = [];
    },
    setConfirm(state, action) {
      state.confirm = !state.confirm;
    },
    addConfirm(state, action) {
      state.addConfirm = !state.addConfirm;
    },
    setChangeButton(state, action) {
      state.changeButton = true;
      state.objectButton = false;
      state.scheduleButton = false;
    },
    disableChangeButton(state, action) {
      state.changeButton = false;
    },
    setPolyline(state, action) {
      state.polyline = action.payload;
    },
    updatePolyline(state, { payload: { index, encoded } }) {
      state.polyline.splice(index, 1, encoded);
    },
    cleanPolyline(state) {
      // console.log('hi')
      state.polyline = [];
      state.addStop = [];
      state.deletedStop = [];
      state.changeStop = [];
    },
    addEmptyPolyline(state) {
      state.polyline.splice(0, 0, '');
      state.polyline.splice(state.polyline.length - 1 + 1, 0, '');
    },
    putRouteRequest(state, action) {
      state.updateRoute = action.payload;
    },
    putRouteStops(state) {
      state.loading = false;
    },
    showAddandSearch(state) {
      state.showAddSearch = true;
    },
    hideAddandSearch(state) {
      state.showAddSearch = false;
    },
    showSchheduleButton(state, action) {
      state.scheduleButton = true;
      state.objectButton = false;
      state.changeButton = false;
    },
    showObjectButton(state, action) {
      state.scheduleButton = false;
      state.objectButton = true;
      state.changeButton = false;
    },
    hideScheduleObjectButton(state, action) {
      state.scheduleButton = false;
      state.objectButton = false;
    },
    cleanSelectedStop(state, action) {
      state.selectedStop = [];
    },
    showAutocomplete(state, action) {
      state.isAutocomplete = true;
    },
    hideAutocomplete(state, action) {
      state.isAutocomplete = false;
    },
    deleteRouteAtStore(state, action) {
      state.stops = state.stops.filter((i) => i.id !== action.payload);
    },
    setDeletePopup(state) {
      state.deletePopup = !state.deletePopup;
    },
    encodePolyline(state, action) {
      state.searched.push(state.stops.filter((i) => i.n == action.payload));
    },
    fetchRoutesStopsRequest(state) {
      state.fetchLoading = true;
    },
    postNewRouteRequest(state) {
      state.fetchLoading = false;
    },
    fetchRoutesStopsFailure(state) {
      state.fetchLoading = false;
    },
    fetchRoutesStopsSuccess(state, action) {
      state.stops = action.payload;
      state.fetchLoading = false;
    },
    searchRoutes(state, action) {
      const check = state.stops.some((i) => i.n === action.payload);
      if (check === false) {
        alert('Маршрут не найден');
        state.notFound = true;
      } else {
        state.searched = state.stops.filter((i) => i.n === action.payload);
      }
    },
    searchReturnAll(state) {
      state.searched = state.stops;
    },
    fetchRoutesGroupsRequest(state) {

    },
    fetchRoutesGroupsFailure(state) {
      state.fetchLoading = false;
    },
    fetchRoutesGroupsSuccess(state, action) {
      state.groups = action.payload;
      state.fetchLoading = false;
    },
    fetchRoutesUnitsRequest(state) {

    },
    fetchRoutesUnitsFailure(state) {
      state.fetchLoading = false;
    },
    fetchRoutesUnitsSuccess(state, action) {
      state.units = action.payload;
      state.allRoutes = false;
    },
    fetchSingleRoutesRequest(state) {
      state.singleRoutesLoad = true;
    },
    // setSingleST(state, action){
    //   state.singleST = state.singleST.map((item,i) => ({
    //     i: i,
    //     p: item.p,
    //     n: item.n,
    //     d: item.d,
    //     id: item.id
    //   }))
    // },
    fetchSingleRoutesSuccess(state, action) {
      state.singleRoutes = action.payload;
      state.stopTab = action.payload.st.map((i) => i.id);
      state.addSchedule = action.payload.st;
      state.singleST = action.payload.st.map((i) => i.id);
      state.singleRoutesLoad = false;
      (state.singleST = state.singleST.map((item, i) => ({
        i: i,
        p: item.p,
        n: item.n,
        d: item.d,
        id: item.id,
      }))),
        (state.stopTab = state.stopTab.map((item, i) => ({
          i: i,
          p: item.p,
          n: item.n,
          d: item.d,
          id: item.id,
        })));
      state.selectedStop = [];
      // console.log('initST',state.stopTab)
    },

    setTemp(state, action) {
      // state.temp = state.singleRoutes.st.map((item, i) => ({
      //   id: item.id.id,
      //   i: item.i,
      //   p: item.p
      // }))
      state.temp = true;

      // console.log(JSON.stringify(state.temp))
    },
    cleanTemp(state, action) {
      state.temp = false;
    },
    fetchSingleRoutesFailure(state) {
      state.singleRoutesLoad = false;
    },
    clearSingleRoutes(state) {
      state.singleRoutes = [];
      // state.singleST = [];
    },
    addRoutesStopRequest(state) {
      state.addLoading = true;
    },
    addRoutesStopSuccess(state, action) {
      state.addLoading = false;
    },
    addRoutesStopFailure(state) {
      state.addLoading = false;
    },
    setTransportType(state, action) {
      state.transportType = action.payload;
    },
    openAssignSaveButton(state) {
      state.saveButton = !state.saveButton;
    },
    assignUnitsToRouteRequest(state) {
      state.assignLoading = true;
    },
    assignUnitsToRouteSuccess(state, action) {
      state.assignRoute = action.payload;
      state.assignLoading = false;
    },
    assignUnitsToRouteFailure(state) {
      state.assignLoading = false;
    },
    assignGroupsToRoutes(state, action) {
      // state.assignGroups = []
      if (state.assignGroups.includes(action.payload[0])) {
        state.assignGroups = state.assignGroups.filter(
          (i) => i !== action.payload[0]
        );
      } else {
        state.assignGroups.push(action.payload[0]);
      }
    },
    assignUnitsFromObj(state, action) {
      console.log('a', action.payload);
      state.assignUnits.push(action.payload);
    },
    clearAssignFromObj(state, action) {
      state.assignUnits = state.assignUnits.filter((i) => i !== action.payload);
    },
    assignUnitsToRoutes(state, action) {
      console.log('a', action.payload);
      // state.assignUnits = [];
      if(state.assignUnits.includes(action.payload.map(e => e))){
          state.assignUnits.length = 0
          state.assignUnits = state.assignUnits.filter(i => i !== action.payload.map(i => i))
      } else {
      state.assignUnits.push(...action.payload);
       }
    },
    clearAssignUnits(state, action) {
      const units = action.payload.map((item) => item.id);
      console.log(units);
      state.assignUnits = state.assignUnits.filter((i) => !units.includes(i));
    },
    clearAssignUnitRoutes(state) {
      state.assignUnits = [];
    },
    showUnitsCount(state, { payload: { groupid, unites } }) {
      if (state.assign[groupid - 1]) {
        state.assign[groupid - 1] = state.assign[groupid - 1].filter(
          (el) => el.groupid !== groupid
        );
      } else {
        state.assign[groupid - 1] = unites;
      }
    },
    setGlobalId(state, action) {
      state.globalID = action.payload;
    },
    setActiveRoute(state, action) {
      state.activeRoute.push(...action.payload);
    },
    cleanActiveRoute(state, action) {
      state.activeRoute = state.activeRoute.filter((i) => i !== action.payload);
    },
    setActiveSchedulle(state) {
      state.saveSchedulle = true;
    },
    setActiveButton(state) {
      state.saveButton = true;
    },
    setRouteName(state, action) {
      state.routeName = action.payload;
    },
    setUpdateTime(state, action) {
      state.setUpdateTime.push(action.payload);
    },
    setOldTime(state, action) {
      state.oldTimeTable = action.payload;
    },
    setNewTime(state, { payload: { idx, time } }) {
      state.oldTimeTable[idx] = time;
    },
    saveUpdateTime(state, action) {
      // if (state.singleRoutes.length > 0) {
      const payload = action.payload;
      state.saveTime = {
        st: payload.st?.map((i) => ({
          id: i.id.id,
          i: i.i,
          p: i.p,
        })),
        tt: [
          {
            t: state.oldTimeTable,
            u: null,
            // t: [
            //   0, 480, 600, 660, 720, 840, 900, 960, 1020, 1080, 1200, 1320,
            //   1380, 1440, 1500, 1560, 1680, 1740, 1800, 1920, 1980, 2040, 2100,
            //   2160, 2220, 2280, 2340, 2400, 2460, 2520, 2580, 2640, 2700, 2760,
            //   2820, 2880, 2940, 3000, 3120, 3180, 3300, 3360, 3420, 3480,
            // ],
          },
        ],
        u: state.singleRoutes.u,
        ug: state.singleRoutes.ug.map((i) => i.id),
        tp: payload.tp,
        a: payload.a,
        n: payload.n,
        d: payload.d,
      };
      console.dir(JSON.stringify(state.saveTime));
    },
    singleRouteST(state) {
      // state.singleST = state.singleRoutes.st;
    },
    deleteRouteStop(state, { payload: { ids, route } }) {
      state.stopTab = route.filter((item, i) => i !== ids);
    },
    cancelRouteStop(state, action) {
      console.log('c', action.payload);
      state.stopTab = action.payload;
    },
    cancelRouteSchedule(state, action) {
      state.deletedSchedule = action.payload;
    },
    setSubmit(state) {
      state.isSubmit = true;
    },
    deletedRoutesSchedule(state, { payload: { ids, route } }) {
      console.log('ids', ids);
      state.deletedSchedule = route?.filter((time, i) => i !== ids);
    },
    cleanDeleteData(state, action) {
      state.deletedStop = [];
      state.deletedStopRoutes = [];
      state.deletedStop = [];
    },
    saveDeletedStop(state, action) {
      state.deletedStop = {
        st: state.stopTab.map((item, i) => ({
          id: item.id,
          i: i,
          p: state.polyline[i],
        })),
        tt: [
          {
            t: state.deletedSchedule,
            u: null,
          },
        ],
        u: state.singleRoutes.u,
        ug: state.singleRoutes.ug.map((i) => i.id),
        tp: state.singleRoutes.tp,
        a: state.singleRoutes.a,
        n: state.singleRoutes.n,
        d: state.singleRoutes.d,
      };
    },
    changedRouteStop(state, { payload: { ids, item } }) {
      state.stopTab.splice(ids, 1, item[0]);
    },
    addNewSchedule(state, action){
      state.schedule = action.payload
    },
    saveUpdatePolyline(state, action) {
      state.updatePolyline = {
        st: state.stopTab.map((item, i) => ({
          id: item.id,
          i: i,
          p: state.polyline[i],
        })),
        tt: [
          {
            t: state.oldTimeTable,
            u: null,
          },
        ],
        u: state.singleRoutes.u,
        ug: state.singleRoutes.ug.map((i) => i.id),
        tp: state.singleRoutes.tp,
        a: state.singleRoutes.a,
        n: state.singleRoutes.n,
        d: state.singleRoutes.d,
      };
      // console.log(JSON.stringify(state.changeStop))
    },
    saveChangeStop(state, action) {
      state.changeStop = {
        st: state.stopTab.map((item, i) => ({
          id: item.id,
          i: i,
          p: state.polyline[i],
        })),
        tt: [
          {
            t: state.singleRoutes.tt[0].t,
            u: null,
          },
        ],
        u: state.singleRoutes.u,
        ug: state.singleRoutes.ug.map((i) => i.id),
        tp: state.singleRoutes.tp,
        a: state.singleRoutes.a,
        n: state.singleRoutes.n,
        d: state.singleRoutes.d,
      };
      // console.log(JSON.stringify(state.changeStop))
    },

    addRouteStop(state, { payload: { ids, obj } }) {
      state.stopTab.splice(ids + 1, 0, obj);
      console.log('ADDItem', ids, obj);
    },
    addRoutesSchedule(state, { payload: { ids } }) {
      state.oldTimeTable.splice(ids + 1, 0, 0);
    },
    saveAddStop(state, action) {
      state.addStop = {
        st: state.stopTab.map((item, i) => ({
          id: item.id,
          i: i,
          p: state.polyline[i],
        })),
        tt: [
          {
            t: state.oldTimeTable,
            u: null,
          },
        ],
        u: state.singleRoutes.u,
        ug: state.singleRoutes.ug.map((i) => i.id),
        tp: state.singleRoutes.tp,
        a: state.singleRoutes.a,
        n: state.singleRoutes.n,
        d: state.singleRoutes.d,
      };
      console.log('SaveItem', state.addStop);
    },
    setSelectedStop(state, action) {
      const payload = action.payload;
      // var poly;
      // payload.p?.forEach((stop) => {
      //   const x = stop.x;
      //   const y = stop.y;
      //
      //   const pol = [[y, x]];
      //   const polyline = encode(pol, 5);
      //   poly = polyline;
      // });
      state.selectedStop = [
        {
          n: payload.n,
          id: payload.id,
          p: payload.p,
          d: payload.d,
        },
      ];
    },
  },
});

export default routesSlice;
