import {makeStyles} from "@mui/styles";

export const useStyles = makeStyles(theme => ({
    input: {
        height: "40px",
    },
    listBox:{
        display:"grid",
        gridTemplateColumns: "380px auto",
        fontSize:"15px"
    },
    horizontalList: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        overflowY: "auto",
        padding: "10px 10px 5px 10px",
        weight: "140px",
        height: "80vh",
    },
    horizontal: {
        // display: "flex",
        // flexDirection: "row",
        flexWrap: "wrap",
        overflow: "scroll",
        padding: "10px 20px 5px 20px",
        weight: "120px",
        height: "80vh",
    },
    row: {
        alignItems: "center",
        display: "flex",
        height: "50px",
        padding: "5px 20px 5px 15px",
        width: "300px",
        cursor: "pointer",
        borderBottom: "1px solid green",
        justifyContent:"space-between",
    },
    name: {
        color: "black",
        paddingLeft: "10px"
    },
    button: {
        color: "gray",
        fontSize:"13px",
        height:"38px",
        border:"1px solid darkgray",
        borderRadius:"0",
        marginRight:"2px",
        backgroundColor: "white",
        textTransform:"capitalize",
        '&:hover': {
            backgroundColor: "whitesmoke",
            border:"1px solid darkgray",
        },
        '&:active':{
            backgroundColor: "whitesmoke",
            border:"1px solid darkgray",
        }
    }
}))