import {Grid, Typography} from "@mui/material";
import Checkbox from "./checkbox";
import {useStyles} from "./style";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {
    assignGroupsToRoutes,
    assignUnitsToRouteRequest,
    assignUnitsToRoutes, assignUnitsToRouteSuccess, clearAssignUnits,
    fetchRoutesGroupsRequest,
    fetchRoutesUnitsRequest, fetchSingleRoutesRequest,
    openAssignSaveButton,
    routesGroupsAndUnitsClean,
    setActiveButton,
    setGlobalId,
    showUnitsCount
} from "../../../store/actions/RoutesActions/routesActions";
import {
  assignCheckUnit,
  checkUnit,
  cleanCheck,
  cleanNameUnit,
  clearIsCheck,
  isCheckUnit,
  setIsCheck,
  setNameUnit,
  uncheckUnit,
} from '../../../store/actions/RoutesActions/routesObjectsActions';
import { useParams } from 'react-router-dom';
import { fetchSingleRoutesThunk } from '../../../store/sagas/routesSagas';

export const Group = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id } = useParams();
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const groups = useSelector((state) => state.routes.groups);
  const units = useSelector((state) => state.routes.units);
  const assignUnits = useSelector((state) => state.routes.assignUnits);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [list, setList] = useState([]);
  const [singleList, setSingleList] = useState([]);
  const isCheck = useSelector((state) => state.objects.check);
  const isUnites = useSelector((state) => state.objects.checkUnit);
  const [isCounts, setIsCounts] = useState([]);
  // const [isCheck, setCheck] = useState([])
  const unitName = useSelector((state) => state.objects.nameUnit);
  const single = useSelector((state) => state.routes.singleRoutes);

  // useEffect(() => {
  //     dispatch(routesGroupsAndUnitsClean())
  //     dispatch(cleanCheck())
  // },[id])

  //show units change groups id
  const changeUnits = (id) => {
    const unite = units.filter((unit) => unit.groupid === id);
  };

  const setActiveGroup = () => {
    const activeGroup = [];
    // const checkGroup = groups.map(i => i.id)
    // if(single.ug[0].id === list[1].id){
    if (single.ug.length > 0) {
      activeGroup.push(single.ug[0].id);
      handleSelectAll(single.ug[0].id);
      // }
      //     dispatch(setIsCheck(activeGroup))
    }
  };

  //     useEffect(() => {
  //     dispatch(fetchRoutesGroupsRequest())
  //     dispatch(fetchRoutesUnitsRequest())
  // },[])

  useEffect(() => {
    // dispatch(fetchSingleRoutesThunk(id))
    const unite = units.filter((unit) => unit.groupid === 1);
  }, []);

  useEffect(() => {
    setList(groups);
  }, []);

  // useEffect(() => {
  //     setActiveGroup()
  // },[])

  const handleSelectAll = (id) => {
    const groupes = groups
      .filter((group) => group.id === id)
      .map((item) => item.id);
    const unites = units.filter((unit) => unit.groupid === id);
    const asUnits = unites.map((item) => item.id);
    dispatch(assignUnitsToRoutes(asUnits));
    dispatch(assignGroupsToRoutes(groupes));
    dispatch(assignUnitsToRouteSuccess(unites));
    dispatch(showUnitsCount({ groupid: id, unites: unites }));

    // dispatch(setActiveButton())
    dispatch(setGlobalId(id));
    dispatch(openAssignSaveButton());
    dispatch(setIsCheck(groupes));
    dispatch(assignCheckUnit());
      dispatch(uncheckUnit());
    if (isCheck.includes(id)) {
      // alert('2')
      dispatch(clearIsCheck(id));
      dispatch(clearAssignUnits(unites));
      dispatch(uncheckUnit());
      setIsCheckAll(false);
      dispatch(cleanNameUnit(unites));
      // clearIsCheckAll();
    } else {
      setIsCheckAll(true);
      dispatch(assignCheckUnit());
      dispatch(setNameUnit(unites));
    }
    if (isCheck) {
      // alert('3')
      // setIsCheck(false);
      // setCheck(isCheck.filter(i => i !== id))
      clearIsCheck(id);
    }
    {
      setIsCheck(!isCheckAll);
    }
  };

  return (
    <div style={{ display: 'block', padding: '0 10px' }}>
      <div style={{ display: 'flex' }}>
        <Grid
          item
          sx={{
            width: '30%',
            color: 'darkgray',
            padding: '10px',
            backgroundColor: 'whitesmoke',
          }}
        >
          <Typography variant={'subtitle1'} sx={{ fontSize: '14px' }}>
            Группы обьектов: {groups && groups.length}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            width: '75%',
            color: 'darkgray',
            padding: '10px',
            backgroundColor: 'whitesmoke',
          }}
        >
          {isCounts && (
            <Typography variant={'subtitle1'} sx={{ fontSize: '14px' }}>
              Найдено элементов: {assignUnits && assignUnits.length}
            </Typography>
          )}
        </Grid>
      </div>
      <div>
        <div className={classes.listBox}>
          <Grid
            item
            // width={'20%'}
            sx={{
              overflow: 'auto',
              height: '80vh',
              backgroundColor: 'whitesmoke',
            }}
          >
            {groups &&
              groups.map((group, index) => (
                <div key={group.id}>
                  <div className={classes.prettyStopRow}>
                    <div className={classes.left}>
                      <div
                        className={`${classes.prettyBorder} ${
                          index === 0 && classes.first
                        }`}
                      >
                        <div
                          className={`${classes.circle} ${
                            index === 0 && classes.first
                          }`}
                        ></div>
                      </div>
                    </div>
                    <div className={classes.right}>
                      <div className={classes.row}>
                        <Checkbox
                          type="checkbox"
                          name={group.name}
                          id={group.id}
                          handleClick={() => handleSelectAll(group.id)}
                          isChecked={isCheck.includes(group.id)}
                          //    const single = useSelector((state) => state.routes.singleRoutes)
                        />
                        <Typography
                          variant={'subtitle2'}
                          className={classes.name}
                          onClick={() => changeUnits(group.id)}
                        >
                          {group.name}{' '}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Grid>
          <Grid className={classes.horizontalList}>
            {unitName &&
              unitName.map((unit, index) => (
                <div className={classes.row} key={unit.id}>
                  <Checkbox
                    key={unit.groupid}
                    type="checkbox"
                    name={unit.name}
                    id={unit.groupid}
                    // handleClick={handleClick}
                    isChecked={isUnites}
                  />
                  <Typography variant={'subtitle2'} className={classes.name}>
                    {unit.name}{' '}
                  </Typography>
                </div>
              ))}
          </Grid>
        </div>
      </div>
    </div>
  );
};