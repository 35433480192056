import routesSlice from "../../slices/Routes/RoutesSlice";


export const {
  fetchRoutesStopsRequest,
  fetchRoutesStopsFailure,
  fetchRoutesStopsSuccess,
  fetchSingleRoutesRequest,
  clearSingleRoutes,
  clearAssignUnitRoutes,
  fetchRoutesGroupsFailure,
  fetchRoutesGroupsRequest,
  fetchRoutesGroupsSuccess,
  fetchRoutesUnitsFailure,
  fetchRoutesUnitsRequest,
  fetchRoutesUnitsSuccess,
  addRoutesStopRequest,
  addRoutesStopSuccess,
  addRoutesStopFailure,
  assignUnitsToRouteRequest,
  assignUnitsToRouteSuccess,
  fetchSingleRoutesSuccess,
  fetchSingleRoutesFailure,
  assignGroupsToRoutes,
  assignUnitsToRoutes,
  fetchAssignUnitsRequest,
  fetchAssignUnitsSuccess,
  showUnitsCount,
  openAssignSaveButton,
  setGlobalId,
  setTemp,
  cleanTemp,
  setActiveRoute,
  routesGroupsAndUnitsClean,
  cleanActiveRoute,
  setActiveButton,
  saveNewRoute,
  saveUpdateTime,
  deleteRouteById,
  cleanActiveButton,
  searchRoutes,
  searchReturnAll,
  postNewRouteRequest,
  saveName,
  deleteRouteAtStore,
  setUpdateTime,
  addConfirm,
  updatePolyline,
  saveUpdatePolyline,
  setDeletePopup,
  setNewTime,
  setOldTime,
  deleteRouteStop,
  cancelRouteStop,
  cancelRouteSchedule,
  setSubmit,
  putDeleteStopsRoutes,
  saveDeletedStop,
  deletedRoutesSchedule,
  addRouteStop,
  saveAddStop,
  singleRouteST,
  cleanSelectedStop,
  changedRouteStop,
  putRouteStops,
  saveChangeStop,
  setSelectedStop,
  setConfirm,
  clearAssignUnits,
  showAutocomplete,
  hideAutocomplete,
  setActiveSchedulle,
  cleanDeleteData,
  showSchheduleButton,
  showObjectButton,
  hideScheduleObjectButton,
  showAddandSearch,
  hideAddandSearch,
  putRouteRequest,
  setPolyline,
  cleanPolyline,
  addEmptyPolyline,
  setChangeButton,
  addRoutesSchedule,
  assignUnitsFromObj,
  clearAssignFromObj,
  groupsAndUnitsClean,
  setSupport,
  closeSupport
} = routesSlice.actions;