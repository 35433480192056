import {makeStyles} from "@mui/styles";
import verticalLineImg from "../../../assets/images/vertical-line.png";

export const useStyles = makeStyles(theme => ({
    container: {
        width: "100%",
        height: "80vh",
    },

    streetsBox: {
        padding: "15px",
    },
    left: {
        height: "100%",
        left: "0",
        position: "absolute",
        textAlign: "right",
        top: "0",
        width: "60px",
    },

    right: {
        borderBottom: "1px solid #e6eaee",
        height: "100%",
        paddingRight: "10px",
        paddingTop: "14px",
    },
    index: {
        color: "rgba(45,44,51,.7)",
        fontSize: "12px",
        margin: "17px 7px 0 0",
    },

    prettyBorder: {
        backgroundImage: `url(${verticalLineImg})`,
        backgroundPositionX: "center",
        backgroundRepeat: "repeat-y",
        bottom: "0",
        left: "17px",
        position: "absolute",
        top: "2px",
        width: "12px",
    },

    circle: {
        backgroundColor: "#ffffff",
        border: "1px solid #999da8",
        borderRadius: "50%",
        height: "12px",
        marginTop: "16px",
        width: "12px",
    },
    prettyStopRow: {
        height: "65px",
        left: "0px",
        position: "relative",
        top: "0px",
        width: "100%",
        paddingLeft: "60px",
        zIndex: "30001",
        overflow:"hidden",
    },
    first:{
        top:"18px",
        marginTop:0,
    }
}));