import React from 'react';
import './App.css';
import Login from "./containers/Login/Login";
import {Route, Routes} from "react-router-dom";
import {apiKey, busStops, dashboard, login, reports, rides, routes, singleRide, tracking} from "./paths";
import BusStops from "./containers/BusStops/BusStops";
import TransportRoutes from "./containers/TransportRoutes/TransportRoutes";
import Layout from "./components/UI/Layout/Layout";
import Dashboard from "./containers/DashBoard/Dashboard";
import Tracking from "./containers/Tracking/Tracking";
import Reports from "./containers/Reports/Reports";
import Rides from "./containers/Rides/Rides";
import SingleRoute from "./containers/SIngleRoute/SingleRoute";
import SingleRide from "./containers/SingleRide/SingleRide";
import Preloader from "./components/UI/Preloader/Preloader";
import DirectionAnimation from "./containers/DIrectionAnimation/DirectionAnimation";
import AddBusStop from "./components/AddBusStop/AddBusStop";
import ApiKey from "./containers/ApiKey/ApiKey";
import CreateSingleRoute from "./containers/createSIngleRoute/createSingleRoute";
import SupportPage from "./containers/Support/SupportPage";


function App() {

  return (
      <Layout>
      <Routes>
          <Route path={busStops} element={<BusStops/>}/>
          <Route path={login} element={<Login/>}/>
          {/*<Route index element={<Login/>}/>*/}
          <Route path={routes} element={<TransportRoutes/>}/>
          <Route index element={<Tracking/>}/>
          {/*<Route  path={dashboard} element={<Dashboard/>}/>*/}
          <Route path={dashboard} element={<Tracking/>}/>
          {/*<Route path={reports} element={<Reports/>}/>*/}
          <Route path={apiKey} element={<ApiKey/>}/>
          <Route path="/tracking/" element={<Tracking/>}/>
          <Route path="/routes/:id/" element={<SingleRoute/>}/>
          <Route path={singleRide} element={<SingleRide/>}/>
          <Route path="/loader/" element={<Preloader/>}/>
          <Route path="/addstop/" element={<AddBusStop/>}/>
          <Route path="/routes/create/" element={<CreateSingleRoute/>}/>
      </Routes>
      </Layout>
  );
}

export default App;

