import React from 'react';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Fade,
  Modal,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import {
  closeSupport,
  setSupport,
} from '../../store/actions/RoutesActions/routesActions';
import wa from '../../assets/images/origin.jpg';
import tm from '../../assets/images/download.png';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '500px',
  height: '300px',
  backgroundColor: 'background.paper',
};

const SupportModal = ({ remove }) => {
  const dispatch = useDispatch();

  const openWhatsApp = () => {
    window.open('https://api.whatsapp.com/send/?phone=996708949884');
    dispatch(closeSupport());
  };

  const openTelegram = () => {
    window.open('https://t.me/imansaparov');
    dispatch(closeSupport());
  };

  const onClose = () => {
    dispatch(closeSupport());
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={remove}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={remove}>
        <Card sx={style} variant="outlined">
          <Box style={{ p: 4 }}>
            <CardContent>
              <Typography style={{ marginBottom: '0px', fontSize: '18px' }}>
                Отправьте возникшую проблему в любом формате по указанной
                соц.сети, и мы решим ее в ближайшее время
              </Typography>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  marginTop: '10px',
                }}
              >
                <img
                  src={tm}
                  style={{ width: '250px', height: '160px' }}
                  onClick={openTelegram}
                />
                <img
                  src={wa}
                  style={{ width: '250px', height: '160px' }}
                  onClick={openWhatsApp}
                />
              </div>
              {/*<Typography style={{marginBottom:"20px"}}>Вы действительно хотите изъять эту остановку?</Typography>*/}
            </CardContent>
            {/*<TextField*/}
            {/*    sx={{width:"300px", marginBottom:"10px"}}*/}
            {/*    id="outlined-helperText"*/}
            {/*    label="Найти остановку"*/}
            {/*    defaultValue="Введите назваие остановки"*/}
            {/*/>*/}
            <Box sx={{ marginLeft: '370px' }}>
              <Button
                color="primary"
                onClick={() => dispatch(closeSupport())}
                variant="outlined"
                sx={{ justifySelf: 'flex-end' }}
              >
                Закрыть
              </Button>
              {/*    <Button color="primary"*/}
              {/*            onClick={() => dispatch(deleteRouteById(id))}*/}
              {/*            variant="outlined">Изьять</Button>*/}
            </Box>
          </Box>
        </Card>
      </Fade>
    </Modal>
  );
};

export default SupportModal;
