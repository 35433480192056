export const busStops = '/stops/';
export const login = '/login';
export const routes = '/routes/';
export const dashboard = '/dashboard/';
export const tracking='/tracking/';
export const reports = '/reports/';
export const apiKey = '/tokens/'
export const rides = '/rides/';
export const singleRoute = '/singleRoute/';
export const singleRide = '/singleRide/';
export const support = '/support/';






