import React, {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import {makeStyles} from "@mui/styles";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import {Checkbox, FormControlLabel, FormGroup, Grid, IconButton, Tooltip, Typography} from "@mui/material";
import ListIcon from '@mui/icons-material/List';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import {nanoid} from "nanoid";
import {useDispatch, useSelector} from "react-redux";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import {
//   fetchRoutesStopsRequest,
//   addRouteStop,
//   saveAddStop,
//   setSelectedStop,
//   hideAutocomplete, addRoutesSchedule, cleanSelectedStop,
// } from '../../../store/actions/RoutesActions/routesActions';
import { useParams } from 'react-router-dom';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { addRouteToState } from '../../store/actions/RoutesActions/createRoutesActions';

const useStyles = makeStyles(() => ({
  autocomplete: {
    fontSize: '16px',
    borderBottom: '1px solid #DFDFDF',
    padding: ' 5px 10px',
    width: '100%',
    height: 'auto',
    position: 'relative',
    overflow: 'visible',
    cursor: 'pointer',
  },

  popoverBox: {
    position: 'absolute',
    top: '0',
    right: '-100px',
    border: '1px solid black',
    padding: '10px',
    zIndex: '555555',
  },
  style: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '750px',
    height: '550px',
    backgroundColor: 'background.paper',
  },
  transports: {
    fontSize: '22px',
  },
  row: {
    alignItems: 'center',
    display: 'flex',
    height: '50px',
    padding: '5px 20px 5px 20px',
    width: '320px',
    cursor: 'pointer',
    borderBottom: '1px solid green',
    justifyContent: 'space-between',
  },
  name: {
    color: 'black',
    paddingLeft: '10px',
  },
}));

const AutocompleteCreateRoutes = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const single = useSelector((state) => state.routes.singleRoutes);
  const item = useSelector((state) => state.routes.selectedStop);
  const classes = useStyles();
  const stops = useSelector((state) => state.stops.stops);
  // const [addElement, setAddElement] = useState([]);

  const saveRoute = (route) => {
    dispatch(addRouteToState(route));
  };

  // useEffect(() => {
  //   dispatch(cleanSelectedStop());
  // }, []);
  //
  // const handleClick = (payload) => {
  //   const obj = {
  //     n: payload.n,
  //     id: payload.id,
  //     p: payload.p,
  //     d: payload.d,
  //   }
  //
  //   dispatch(setSelectedStop(payload));
  //   dispatch(hideAutocomplete());
  //       dispatch(addRouteStop({ ids, obj }));
  //       dispatch(addRoutesSchedule({ ids }));
  //     setTimeout(() => {
  //       dispatch(saveAddStop());
  //     }, 2000);
  //   // dispatch(setSelectedStop(addElement));
  // };
  console.log(stops);

  return (
    <>
      <Autocomplete
        id="combo-box-demo"
        open={true}
        options={stops}
        style={{
          height: '80%',
          width: '100%',
        }}
        ListboxProps={{ style: { maxHeight: '50vh' } }}
        getOptionLabel={(option) =>
          `${option.n} ${option.p[0]?.x} ${option.p[0]?.y}`
        }
        renderOption={(props, option) => {
          return (
            <div>
              <div className={styles.row}>
                <div style={{ margin: '2px 0',lineHeight: '1.3' }}>
                  <p>{option.n}</p>
                  <i style={{ fontSize: '12px' }}>
                    {option.p[0].x} , {option.p[0].y}
                  </i>
                </div>
                <div style={{ cursor: 'pointer' }}>
                  <TrendingFlatIcon onClick={() => saveRoute(option)} />
                </div>
              </div>
            </div>
            // <>
            //   <div
            //     className={classes.autocomplete}
            //     // onClick={(e) => onStopClick(e, option.id)}
            //     key={nanoid()}
            //     id={option.id}
            //   >
            //     <div onClick={() => handleClick(option)}>
            //       {option.n}
            //       <p>
            //         <i style={{ fontSize: '12px' }}>
            //           {option.p[0].x} , {option.p[0].y}
            //         </i>
            //       </p>
            //     </div>
            //   </div>
            // </>
          );
        }}
        sx={{}}
        renderInput={(params) => <TextField {...params} label="Поиск" />}
      />
    </>
  );
};

export default AutocompleteCreateRoutes;