import {createSlice} from "@reduxjs/toolkit";
import {setTimeTable} from "../../actions/RoutesActions/routesObjectsActions";

const name = 'route';

const initialState = {
  transportType: null,
  assignUnits: [],
  assignGroups: [],
  activeRoute: [],
  fetchLoading: false,
  singleRoutes: [],
  saveButton: false,
  pageCount: 1,
  globalID: null,
  saveSchedulle: false,
  saveRoutes: [],
  addRoute: [],
  saveTime: [],
  routeName: null,
  saveStore: [],
  decoded: [],
  setUpdateTime: [],
  oldTimeTable: [],
  polyline: null,
  slicedStop: null,
  addPopup: false
};

const createRoutesSlice = createSlice({
  name,
  initialState,
  reducers: {
    cleanSaveData(state) {
      state.transportType = [];
      state.saveTime = [];
      state.routeName = [];
      state.assignRoute = [];
      state.saveStore = [];
      state.assignGroups = [];
      state.assignUnits = [];
      state.polyline = [];
      state.decoded = [];
      state.setUpdateTime = [];
      state.saveRoute = [];
      state.oldTimeTable = [];
      state.check = [];
      state.confirm = false

    },
    setConfirm(state, action) {
      state.confirm = !state.confirm;
    },
    setIsCheck(state, action) {
      state.check.push(...action.payload);
    },
    clearIsCheck(state, action) {
      state.check = state.check.filter( i => i !== action.payload);
    },
    clearAssignUnits(state, action) {
      const units = action.payload.map((item) => item.id);
      console.log(units);
      state.assignUnits = state.assignUnits.filter((i) => !units.includes(i));
    },
    setAddPopup(state){
      state.addPopup = true
    },
    cleanAddPopup(state){
      state.addPopup = false
    },
    setTransportType(state, action) {
      state.transportType = action.payload;
    },
    setTimeTable(state, action) {
      state.saveTime.push(action.payload);
    },
    saveRouteName(state, action) {
      state.routeName = action.payload;
    },
    clearSingleRoutes(state) {
      state.singleRoutes = [];
    },
    addRoutesStopRequest(state) {
      state.addLoading = true;
    },
    addRoutesStopSuccess(state, action) {
      state.addLoading = false;
    },
    addRoutesStopFailure(state) {
      state.addLoading = false;
    },
    openAssignSaveButton(state) {
      state.saveButton = !state.saveButton;
    },
    assignUnitsToRouteRequest(state) {
      state.assignLoading = true;
    },
    assignUnitsToRouteSuccess(state, action) {
      state.assignRoute = action.payload;
      state.assignLoading = false;
    },
    // assignUnitsToRouteFailure(state) {
    //     state.assignLoading = false
    // },
    addRouteToState(state, action) {
      state.saveStore.push(action.payload);
    },
    assignGroups(state, action) {
      if (state.assignGroups.includes(action.payload[0])) {
        state.assignGroups = state.assignGroups.filter(
          (i) => i !== action.payload[0]
        );
      } else {
        state.assignGroups.push(action.payload[0]);
      }
    },
    assignUnits(state, action) {
      // if(state.assignUnits.every(x => action.payload.includes(x))){
      //     state.assignUnits.length = 0
      //     // state.assignUnits = state.assignUnits.filter(i => i !== action.payload.map(i => i))
      // } else {
      state.assignUnits.push(...action.payload);
      // }
    },
    routesGroupsAndUnitsClean(state) {
      state.activeRoute = []
      state.assignGroups = [];
      state.assignUnits = [];
    },
    clearAssignUnitRoutes(state) {
      state.assignUnits = [];
    },
    // showUnitsCount(state, {payload: {groupid, unites}}) {
    //     // console.log(groupid)
    //     // console.log(unites)
    //     if (state.assign[groupid-1]) {
    //         state.assign[groupid-1]=state.assign[groupid-1].filter((el) => el.groupid !== groupid)
    //     } else {
    //         state.assign[groupid - 1] = unites
    //     }
    // },
    setGlobalId(state, action) {
      state.globalID = action.payload;
    },
    setActiveRoute(state, action) {
      state.activeRoute.push(...action.payload);
    },
    cleanActiveRoute(state, action) {
      state.activeRoute = state.activeRoute.filter((i) => i !== action.payload);
    },
    setActiveSchedulle(state) {
      state.saveSchedulle = true;
    },
    setRouteName(state, action) {
      state.routeName = action.payload;
    },
    setPolylineDecode(state, action) {
      state.decoded.push(action.payload);
    },
    setPolyline(state, action) {
      state.polyline = action.payload;
    },
    createSetUpdateTime(state, action) {
      state.setUpdateTime.push(action.payload);
    },
    // setOldTime(state, action){
    //     state.oldTimeTable = action.payload
    // },
    createSetNewTime(state, { payload: { idx, time } }) {
      state.oldTimeTable[idx] = time;
    },
    fillEmptyTime(state){
      state.oldTimeTable[0] = 0
    },
    sliceFistStop(state) {
      state.slicedStop = state.saveStore.slice(1);
    },
    addEmptyPolyline(state) {
      state.polyline.splice(0, 0, '');
    },
    saveNewRoute(state) {
      state.saveRoute = {
        st: state.saveStore.map((item, index) => ({
          id: item.id,
          i: index,
          p: state.polyline[index],
        })),
        // st: state.saveStore.map((item, index) => ({
        //   id: item.id,
        //   i: index,
        //   p: state.decoded[index],
        // })),
        tt: [
          {
            t: [...state.oldTimeTable],
            u: null,
          },
        ],
        u: state.assignUnits,
        ug: state.assignGroups,
        tp: state.transportType,
        n: state.routeName,
        a: true,
        d: '',
      };
      // console.dir(JSON.stringify(state.saveRoute));
    },
  },
});

;

export default createRoutesSlice;