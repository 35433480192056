import React, {useState, Suspense, useEffect} from 'react';
import {
    Grid,
    Menu,
    Button,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    TextField,
    Tooltip,
    IconButton, InputAdornment
} from "@mui/material";
import CommuteIcon from '@mui/icons-material/Commute';
import DirectionsBusFilledIcon from '@mui/icons-material/DirectionsBusFilled';
import DirectionsTransitIcon from '@mui/icons-material/DirectionsTransit';
import TramIcon from '@mui/icons-material/Tram';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import BusAlertIcon from '@mui/icons-material/BusAlert';
import HotelIcon from '@mui/icons-material/Hotel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {makeStyles} from "@mui/styles";
import {blue, green, pink, yellow} from "../../colors";
import {TabsContainer} from "../../assets/styles/tabs/tabs";
import Preloader from "../../components/UI/Preloader/Preloader";
import {useDispatch, useSelector} from "react-redux";
import {fetchRidesRequest, fetchRidesStatistic} from "../../store/sagas/ridesSagas";
import {setDelayed, switchGeneral} from "../../store/actions/RoutesActions/ridesActions";
import {fetchRoutesStopsRequest, searchReturnAll, searchRoutes} from "../../store/actions/RoutesActions/routesActions";
import SearchIcon from '@mui/icons-material/Search';
import {
    fetchStopsForBusRequest,
    fetchStopsForTaxiRequest,
    fetchStopsForTrolleybusRequest, fetchUnknownStopsRequest
} from "../../store/actions/stopsActions";
const TrackingStops = React.lazy(() => import("../../components/TrackingStops/TrackingStops"))

const useStyle = makeStyles(theme => ({
    box: {
        borderRight: "0.5px solid lightgrey",
        fontSize: '13px',
        display: 'flex',
        alignItems: "center",
        textAlign: "center",
        width: "90px",
        padding: "0 10px",
        cursor: "pointer",
        caretColor: "transparent",
    },
    active: {
        backgroundColor: "#cbd9d8",
    }
}))

const Tracking = () => {
    const dispatch = useDispatch()
    const classes = useStyle();
    const [clickedDiv, setClickedDiv] = useState("all");
    const [search, setSearch] = useState("");
    const onDivClick = (e) => {
        setClickedDiv(e.target.id)
    }
    const [type, setType] = React.useState("Все");
    const statistic = useSelector(state => state.rides.statistic);
    const delayed = useSelector(state => state.rides.delayed);
    const outRun = useSelector(state => state.rides.countOutRun);
    const sigleStat = useSelector(state => state.rides.singleRide);
    const generalStat = useSelector(state => state.rides.generalStat);
    const singleStat = useSelector(state => state.rides.singleStat);
    const delayedSingle = useSelector(state => state.rides.delaySingle);
    const outRunSingle = useSelector(state => state.rides.outrunSingle);
console.log(delayedSingle)
    useEffect(() => {
        dispatch(fetchRidesStatistic())
        dispatch(fetchRidesRequest())
        dispatch(fetchRoutesStopsRequest())
    }, [])

    useEffect(()=>{
        dispatch(fetchStopsForBusRequest(`?cnt_tp=1`))
        dispatch(fetchStopsForTrolleybusRequest(`?cnt_tp=2`))
        dispatch(fetchStopsForTaxiRequest(`?cnt_tp=3`))
        dispatch(fetchUnknownStopsRequest(`?cnt_tp=0`))
    },[])

    const searchSubmit = (search) => {
        if(search == ""){
            dispatch(searchReturnAll())
            // dispatch(fetchRidesRequest())
            // dispatch(switchGeneral())
        } else {
            dispatch(searchRoutes(search))
        }
    }


    const style = {
      fontSize: '18px',
      marginRight: '10px',
    };

    const transportArray = [
      {
        id: 1,
        icon: <CommuteIcon sx={{ ...style, color: blue }} />,
        name: 'Все',
      },
      {
        id: 2,
        icon: <DirectionsBusFilledIcon sx={{ ...style, color: green }} />,
        name: 'Автобусы',
      },
      {
        id: 3,
        icon: <DirectionsTransitIcon sx={{ ...style, color: blue }} />,
        name: 'Тролейбусы',
      },

      {
        id: 5,
        icon: <LocalTaxiIcon sx={{ ...style, color: yellow }} />,
        name: 'Маршрутные такси',
      },
    ];

    const handleChange = (e) => {
        setType(e.target.value);
    };

    return (
        <Grid>
            <TabsContainer>
                <Grid container sx={{marginTop: "5px"}}>
                    <Grid item sx={{width: '10%'}}>

                        <FormControl fullWidth className={classes.input}>
                            <InputLabel id="demo-simple-select-label">Тип</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={type}
                                label="Тип"
                                onChange={handleChange}
                            >
                                {transportArray.map((item) => (
                                    <MenuItem key={item.id} id={item.id} value={item.name} onClick={handleChange}>
                                        {item.icon}
                                        <span>{item.name}</span>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {generalStat && (
                        <Grid item container sx={{width: '60%', border: '1px solid lightgrey'}}>
                            <Tooltip title="Все" arrow>
                                <Grid item className={`${classes.box} ${clickedDiv === "all" && classes.active}`}
                                      id={"all"}
                                      onClick={(e) => onDivClick(e)}>
                                    <DirectionsBusFilledIcon
                                        sx={{
                                            color: clickedDiv === "all" ? "grey" : "darkgrey",
                                            fontSize: "18px",
                                            marginRight: "10px"
                                        }}
                                    />
                                    {statistic && statistic.length}

                                </Grid>
                            </Tooltip>
                            <Tooltip title="С отставанием" arrow>
                                <Grid item className={`${classes.box} ${clickedDiv === "slow" && classes.active}`}
                                      id={"slow"}
                                      onClick={(e) => onDivClick(e)}>
                                    <ArrowBackIcon
                                        sx={{
                                            color: clickedDiv === "slow" ? "#ff5f5f" : "darkgrey",
                                            fontSize: "18px",
                                            marginRight: "10px"
                                        }}/>
                                    {delayed && delayed}

                                </Grid>
                            </Tooltip>
                            <Tooltip title="С опережением" arrow>
                                <Grid item className={`${classes.box} ${clickedDiv === "fast" && classes.active}`}
                                      id={"fast"}
                                      onClick={(e) => onDivClick(e)}>
                                    <ArrowForwardIcon
                                        sx={{
                                            color: clickedDiv === "fast" ? "#FFB511FF" : "darkgrey",
                                            fontSize: "18px",
                                            marginRight: "10px"
                                        }}
                                    />
                                    {outRun && outRun}
                                </Grid>
                            </Tooltip>
                            {/*<Tooltip title="Без типа" arrow>*/}
                            {/*<Grid item className={`${classes.box} ${clickedDiv === "unknown" && classes.active}`}*/}
                            {/*      id={"unknown"}*/}
                            {/*      onClick={(e) => onDivClick(e)}>*/}
                            {/*    <BusAlertIcon*/}
                            {/*        sx={{*/}
                            {/*            color: clickedDiv === "unknown" ? blue : "darkgrey",*/}
                            {/*            fontSize: "18px",*/}
                            {/*            marginRight: "10px"*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*    0*/}
                            {/*</Grid>*/}
                            {/*</Tooltip>*/}
                        </Grid>
                    )}
                    {singleStat && (
                        <Grid item container sx={{width: '60%', border: '1px solid lightgrey'}}>
                            <Tooltip title="Все" arrow>
                                <Grid item className={`${classes.box} ${clickedDiv === "all" && classes.active}`}
                                      id={"all"}
                                      onClick={(e) => onDivClick(e)}>
                                    <DirectionsBusFilledIcon
                                        sx={{
                                            color: clickedDiv === "all" ? "grey" : "darkgrey",
                                            fontSize: "18px",
                                            marginRight: "10px"
                                        }}
                                    />
                                    {sigleStat && sigleStat.length}

                                </Grid>
                            </Tooltip>
                            <Tooltip title="С отставанием" arrow>
                                <Grid item className={`${classes.box} ${clickedDiv === "slow" && classes.active}`}
                                      id={"slow"}
                                      onClick={(e) => onDivClick(e)}>
                                    <ArrowBackIcon
                                        sx={{
                                            color: clickedDiv === "slow" ? "#ff5f5f" : "darkgrey",
                                            fontSize: "18px",
                                            marginRight: "10px"
                                        }}/>
                                    {delayedSingle && delayedSingle.length}

                                </Grid>
                            </Tooltip>
                            <Tooltip title="С опережением" arrow>
                                <Grid item className={`${classes.box} ${clickedDiv === "fast" && classes.active}`}
                                      id={"fast"}
                                      onClick={(e) => onDivClick(e)}>
                                    <ArrowForwardIcon
                                        sx={{
                                            color: clickedDiv === "fast" ? "#FFB511FF" : "darkgrey",
                                            fontSize: "18px",
                                            marginRight: "10px"
                                        }}
                                    />
                                    {outRunSingle && outRunSingle.length}
                                </Grid>
                            </Tooltip>
                            {/*<Tooltip title="Без типа" arrow>*/}
                            {/*<Grid item className={`${classes.box} ${clickedDiv === "unknown" && classes.active}`}*/}
                            {/*      id={"unknown"}*/}
                            {/*      onClick={(e) => onDivClick(e)}>*/}
                            {/*    <BusAlertIcon*/}
                            {/*        sx={{*/}
                            {/*            color: clickedDiv === "unknown" ? blue : "darkgrey",*/}
                            {/*            fontSize: "18px",*/}
                            {/*            marginRight: "10px"*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*    0*/}
                            {/*</Grid>*/}
                            {/*</Tooltip>*/}
                        </Grid>
                    )}
                    <Grid item sx={{width: '30%', display: 'flex'}}>
                        <TextField type={"number"}
                                   placeholder={"Поиск по маршруту"}
                                   sx={{width: '100%'}}
                                   onChange={(e) => setSearch(e.target.value)}
                                   onKeyDown={(e) => e.key === 'Enter' && searchSubmit(search)}
                                   InputProps={{
                                       startAdornment: (
                                           <InputAdornment position="start">
                                               <SearchIcon />
                                           </InputAdornment>
                                       ),
                                   }}
                        />
                    </Grid>
                </Grid>
            </TabsContainer>
            <Suspense fallback={<Preloader/>}>
            <TrackingStops/>
            </Suspense>
        </Grid>
    );
};

export default Tracking;
