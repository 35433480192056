import React from 'react';
import {Backdrop, Box, Button, Card, CardContent, Fade, Modal} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useDispatch} from "react-redux";
import {deleteRouteById} from "../../store/sagas/routesSagas";
import {deleteRouteAtStore, setDeletePopup} from "../../store/actions/RoutesActions/routesActions";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "500px",
    height: "200px",
    backgroundColor: 'background.paper',
}

const DeleteModal = ({remove, onClose, name, id}) => {
    const dispatch = useDispatch()

    const handleDelete = (id) => {
        dispatch(deleteRouteAtStore(id))
        dispatch(deleteRouteById(id))

    }


    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={remove}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={remove}>
                <Card sx={style} variant="outlined">
                    <Box style={{fontWeight:"bold", padding:"15px", backgroundColor: "blue", color:"white"}} >
                        {/*{name.st[0].id.n + " - " + name.st[1].id.n} */}
                    </Box>
                    <Box style={{p:2}}>
                        <CardContent>
                            <Typography style={{marginBottom:"20px"}}>Вы действительно хотите удалить этот маршрут?</Typography>
                        </CardContent>
                        {/*<TextField*/}
                        {/*    sx={{width:"300px", marginBottom:"10px"}}*/}
                        {/*    id="outlined-helperText"*/}
                        {/*    label="Найти остановку"*/}
                        {/*    defaultValue="Введите назваие остановки"*/}
                        {/*/>*/}
                        <Box sx={{pl:18}}>
                            <Button color="primary"
                                    onClick={onClose}
                                    variant="outlined"
                                    sx={{mr:"8px"}}>Отменить</Button>
                            <Button color="primary"
                                    onClick={() => handleDelete(id)}
                                    variant="outlined">Удалить</Button>
                        </Box>
                    </Box>
                </Card>
            </Fade>
        </Modal>
    );
};

export default DeleteModal;