import React, {useEffect, useMemo, useRef, useState} from "react";
import {compose, lifecycle, withProps} from "recompose";
// import decodePolyline from "decode-google-map-polyline";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Polyline,
    DirectionsRenderer,
    Marker, InfoWindow
} from "react-google-maps";
import {ColorButton,E} from "./style";
import CreateAddModal from "./createAddModal";
import CreateChangeModal from "./createChangeModal";
import CreateRemoveModal from "./createRemoveModal";
import L from "leaflet"
import {decode, encode} from "@googlemaps/polyline-codec";
import {connect, useDispatch, useSelector} from "react-redux";
import {addEmptyPolyline, setPolylineDecode} from "../../../store/actions/RoutesActions/createRoutesActions";
import {setPolyline} from "../../../store/actions/RoutesActions/createRoutesActions";
import {wayPoints} from "./waypoints";
import {bindActionCreators} from "redux";
import {setChangeButton, setDSresult} from "../../../store/actions/RoutesActions/routesActions";
import FlagFill from "../../../assets/images/bus.png"
import simplify from "simplify-js";
import polyline from 'polyline-encoded';
import ConfirmModal from "../../../containers/SIngleRoute/confirmModal";
import CreateConfirmModal from "../../../containers/createSIngleRoute/createConfirmModal";



const CreateMap = withScriptjs(
    withGoogleMap((props) => {
        const data = useSelector((state => state.createRoutes.saveStore))
        const [openTooltip, setOpenTooltip] = useState("")
        const [selectedMarker, setSelectedMarker] = useState("");
        const [open, setOpen] = React.useState(false);
        const [remove, setRemove] = React.useState(false);
        const [change, setChange] = React.useState(false);
        const [direction, setDirection] = useState([]);
        const dispatch = useDispatch()
        const directionsRef = useRef(null);
        const handleAdd = () => setOpen(!open);
        const handleRemove = () => setRemove(!remove);
        const handleChange = () => setChange(!change);
        var g = window.google;

        useEffect(() => {
            let waypointsArray = [];
            let totalWaypoints = data;
            var directionsService;
            var l = null;
            var c = null;
            var e = null;
            var r = null;

            // console.log('tw', totalWaypoints)
            if (totalWaypoints.length > 0) {
                for (let i = 0; i < totalWaypoints.length; i += 20) {
                    waypointsArray.push(totalWaypoints.slice(i, i + 20));
                }
                const promises = [];

                waypointsArray.forEach((item) => {
                    directionsService = new g.maps.DirectionsService();
                    let waypoints = item;
                    l = waypoints[0].p[0].y.toString();
                    c = waypoints[0].p[0].x.toString();
                    e = waypoints[waypoints.length - 1].p[0].y.toString();
                    r = waypoints[waypoints.length - 1].p[0].x.toString();
                    const waypts = [];

                    waypoints.slice(0, waypoints.length - 1).forEach((stop) => {
                        const obj = {
                            location: `${stop.p[0].y}, ${stop.p[0].x}`,
                        };
                        waypts.push(obj);
                    });

                    const promise = new Promise((resolve, reject) => {
                        directionsService.route(
                            {
                                origin: new g.maps.LatLng(l, c),
                                destination: new g.maps.LatLng(e, r),
                                waypoints: waypts,
                                optimizeWaypoints: true,
                                travelMode: g.maps.TravelMode.DRIVING
                            },

                            (result, status) => {
                                if (status === g.maps.DirectionsStatus.OK) {
                                    resolve(result);
                                } else {
                                    reject(`error fetching directions ${result}`);
                                }
                            }
                        );
                    });
                    promises.push(promise);
                });

                Promise.all(promises)
                    .then((results) => {
                        console.log('r', results);
                        setDirection(results);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
    }, [data])



    // for(let i=0; i<d.length; i+2){
    // if(d.length > 0) {
        // for(let i=0; i<d.length; i++){
        useEffect(() => {
            const arr = [];
            const decPoly = direction
                ?.map((item) =>
                    item.routes
                        .map((route) =>
                            route.legs.map((leg) =>
                                leg.steps.map((step) => step.polyline.points).flat(2)
                            )
                        )
                        .flat(1)
                )
                .flat(1);
            for (let i = 0; i < decPoly.length; i++) {
                const roundedPoints = decPoly[i].map(point => decode(point)).flat(1)

                if(roundedPoints.length > 100 ){
                    for (let i = roundedPoints.length - 1; i >= 0; i--) {
                        if (i % 2 !== 0) {
                            roundedPoints.splice(i, 1);
                        }
                    }
                }

                if(roundedPoints.length > 200 ){
                    for (let i = roundedPoints.length - 1; i >= 0; i--) {
                        if (i % 2 !== 0) {
                            roundedPoints.splice(i, 2);
                        }
                    }
                    // for (let i = roundedPoints.length - 1; i >= 0; i--) {
                    //     if (i % 2 !== 0) {
                    //         roundedPoints.splice(i, 1);
                    //     }
                    // }
                }

                console.log(roundedPoints)
                const outputPolyline = encode(roundedPoints, 5);
                console.log(outputPolyline);
                arr.push(outputPolyline);;
            }
            console.log('poly', arr);
            dispatch(setPolyline(arr));
        },[direction]);

        const onDirectionChanged = () => {
            const newDirections = new window.google.maps.DirectionsRenderer()
            console.log(newDirections);
        };

        console.log('d', direction);
    return (
        <GoogleMap defaultZoom={props.zoom} defaultCenter={props.center}>
                        {direction &&
                            direction.map((i) => (
                                <DirectionsRenderer
                                    ref={directionsRef}
                                    directions={i}
                                    options={{ draggable: true, suppressMarkers: true }}
                                    onDirectionsChanged={() => {
                                        const newDirections = directionsRef.current.getDirections();
                                        setDirection([newDirections]);
                                    }}
                                />
                            ))}
            {props.stops &&
                props.stops.map((item, i) => (
                    <div key={i}>
                        <Marker
                          position={{
                            lat: item.p[0].y,
                            lng: item.p[0].x,
                          }}
                          label={(i + 1).toString()}
                          onMouseDown={() => {
                            setSelectedMarker(item);
                            setOpenTooltip('');
                          }}
                          onMouseOver={() => {
                            setOpenTooltip(item);
                          }}
                          onMouseOut={() => {
                            setOpenTooltip(false);
                          }}

                        />
                        {openTooltip && (
                            <InfoWindow
                                options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
                                position={{
                                    lat: openTooltip.p[0].y,
                                    lng: openTooltip.p[0].x,
                                }}
                                onCloseClick={() => setOpenTooltip(false)}
                            >
                                <h4>
                                    {openTooltip.n}
                                    <br />
                                    {openTooltip.d}
                                </h4>
                            </InfoWindow>
                        )}

                    </div>
                ))}
        </GoogleMap>
    );
    })
);





export default CreateMap
