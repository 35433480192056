import React from 'react';

const Overlay = () => {
    return (
        <div

        style={{
            backgroundColor: "rgba(255,255,255,0.5)",
            height: "100%",
            left: "0",
            position: "absolute",
            top: "0",
            width: "100%",
            zIndex:"222"
        }}
        >

        </div>
    );
};

export default Overlay;