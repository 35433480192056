import React, {useEffect, useState} from 'react';
import {Grid, Typography} from "@mui/material";
import {useStyles} from "./style";
import {useDispatch, useSelector} from "react-redux";
import {setTimeTable} from "../../../store/actions/RoutesActions/routesObjectsActions";
import {setScheduleButton} from "../../../store/actions/RoutesActions/routesScheduleActions";
import TextField from "@mui/material/TextField";
import DayJS from "react-dayjs/source";
import {
  saveUpdateTime,
  setOldTime,
  showSchheduleButton,
} from '../../../store/actions/RoutesActions/routesActions';
import TimeField from './TimeField';

const ScheduleTab = () => {
  const [value, setValue] = useState([]);
  const [index, setIndex] = useState([]);
  const [time, setTime] = useState([]);
  const classes = useStyles();
  const stop = useSelector((state) => state.routes.singleRoutes);
  const dispatch = useDispatch();
  var countST = 0;
  var countTT = 0;
  var assignTT = [];

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(value);
    dispatch(setTimeTable(value));
    // dispatch(setTimeTable({'val': value,'idx': index}))
  };

  useEffect(() => {
    dispatch(saveUpdateTime(stop));
    dispatch(setOldTime(stop.tt[0]?.t));
    dispatch(showSchheduleButton());
  }, []);

  const onChange = () => {
    dispatch(setScheduleButton());
  };

  const setKey = (e) => {
    e.preventDefault();
    alert(time);
    setValue(e.target.value);
    dispatch(setScheduleButton());
  };

  // stop.st.forEach(i => {
  //     countST += 1
  // })
  // stop.tt.t.forEach(i => {
  //     countTT += 1
  // })

  // const emptyCount = stop.st.length - stop.tt[0].t.length
  // for(let i=0; i<emptyCount; i++){
  //     assignTT.push(i)
  // }
  // stop.tt[0].t.push(assignTT)

  return (
    <Grid container sx={{ overflow: 'auto', height: '80vh' }}>
      <Grid item width={'28%'} sx={{ marginRight: '20px' }}>
        <div
          style={{
            margin: '5px 0',
            borderBottom: '1px solid lightgrey',
            color: 'gray',
            backgroundColor: 'whitesmoke',
            padding: '10px',
          }}
        >
          <Typography variant={'body1'}>
            Количество остановок: {stop.st.length}
          </Typography>
        </div>
        {stop &&
          stop.st.map((stops, index) => (
            <div className={classes.prettyStopRow} key={index}>
              <div className={classes.left}>
                <div className={classes.index}>{index + 1}</div>
                <div
                  className={`${classes.prettyBorder} ${
                    index === 0 && classes.first
                  }`}
                >
                  <div
                    className={`${classes.circle} ${
                      index === 0 && classes.first
                    }`}
                  ></div>
                </div>
              </div>
              <div className={classes.right}>
                {stops.id.n}
                <p>
                  <i style={{ fontSize: '12px' }}>{stops.id.d}</i>
                </p>
              </div>
            </div>
          ))}
      </Grid>
      <Grid item flexGrow={1}>
        <div
          style={{
            margin: '5px 0',
            borderBottom: '1px solid lightgrey',
            color: 'gray',
            backgroundColor: 'whitesmoke',
            padding: '10px',
          }}
        >
          <Typography variant={'body1'}>Время: 00:00</Typography>
        </div>
        {stop.tt.map((stops) =>
          stops.t.map((time, idx) => (
            <div
              style={{ borderBottom: '1px solid lightgrey', height: '65px' }}
            >
              <TimeField time={time} idx={idx} />
            </div>
          ))
        )}
      </Grid>
    </Grid>
  );
};

export default ScheduleTab;