import {createSlice} from "@reduxjs/toolkit";

const name = 'route';

const initialState = {
    activeSchedulle: false,
    scheduleButton: false,
    saveStore: [],
    addRoute: [],
    saveTime: []
}

const routesScheduleSlice = createSlice({
        name,
        initialState,
        reducers: {
            setActiveSchedulle(state){
                state.activeSchedulle = true
            },
            cleanActiveSchedulle(state){
                state.activeSchedulle = false
            },
            setScheduleButton(state){
                state.scheduleButton = true
            },
            saveUpdateTime(state, action) {
                // if (state.singleRoutes.length > 0) {
                state.saveTime.push(action.payload
                    // {
                    //     "st": [
                    //         {
                    //             "id": {
                    //                 "p": [
                    //                     {
                    //                         "x": action.payload.st[0].id.p[0].x,
                    //                         "y": action.payload.st[0].id.p[0].y,
                    //                         "r": action.payload.st[0].id.p[0].r
                    //                     }
                    //                 ],
                    //                 "sh": action.payload.st[0].id.sh,
                    //                 "d": action.payload.st[0].id.d,
                    //                 "tp": action.payload.st[0].id.tp,
                    //                 "n": action.payload.st[0].id.n
                    //             },
                    //             "i": action.payload.st[0].i,
                    //             "p": action.payload.st[0].p
                    //         }
                    //     ],
                    //     "tt": [
                    //         {
                    //             "t": [
                    //                 0,
                    //                 480,
                    //                 600,
                    //                 660,
                    //                 720,
                    //                 840,
                    //                 900,
                    //                 960,
                    //                 1020,
                    //                 1080,
                    //                 1200,
                    //                 1320,
                    //                 1380,
                    //                 1440,
                    //                 1500,
                    //                 1560,
                    //                 1680,
                    //                 1740,
                    //                 1800,
                    //                 1920,
                    //                 1980,
                    //                 2040,
                    //                 2100,
                    //                 2160,
                    //                 2220,
                    //                 2280,
                    //                 2340,
                    //                 2400,
                    //                 2460,
                    //                 2520,
                    //                 2580,
                    //                 2640,
                    //                 2700,
                    //                 2760,
                    //                 2820,
                    //                 2880,
                    //                 2940,
                    //                 3000,
                    //                 3120,
                    //                 3180,
                    //                 3300,
                    //                 3360,
                    //             ],
                    //             "u": action.payload.tt[0].u
                    //         }
                    //     ],
                    //     "ug": [
                    //         {
                    //             "name": action.payload.ug[0].name,
                    //             "attributes": action.payload.ug[0].attributes,
                    //             "groupid": action.payload.st[0].groupid,
                    //             "ug": action.payload.ug[0].ug
                    //         }
                    //     ],
                    //     "tp": action.payload.tp,
                    //     "a": action.payload.a,
                    //     "n": action.payload.n,
                    //     "d": action.payload.d
                    // }
                )
            }
        }
        // }
    })

;

export default routesScheduleSlice;