/*global google*/
import React, { useEffect, useState } from 'react';
import { Grid, IconButton } from '@mui/material';
import { ColorButton, useStyles } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { useLoadScript } from '@react-google-maps/api';
import { fetchStopsRequest } from '../../../store/actions/stopsActions';
import {
  cleanSelectedStop,
  showAutocomplete,
  hideScheduleObjectButton, showAddandSearch, cancelRouteStop, setOldTime,
} from '../../../store/actions/RoutesActions/routesActions';
import Map from './Map';
import Preloader from '../../UI/Preloader/Preloader';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import AddModal from './AddModal';
import ChangeModal from './changeModal';
import RemoveModal from './removeModal';
import Tooltip from '@mui/material/Tooltip';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import decodePolyline from "decode-google-map-polyline";

const style = {
  fontSize: '18px',
  color: 'grey',
  padding: '0',
  margin: '0 2px',
};

const StopsTab = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const single = useSelector((state) => state.routes.singleRoutes);
  const singleST = useSelector((state) => state.routes.singleST);
  const [selectedMarker, setSelectedMarker] = useState('');
  const [open, setOpen] = React.useState(false);
  const [remove, setRemove] = React.useState(false);
  const [change, setChange] = React.useState(false);
  const [showBtn, setShowBtn] = useState(null);
  const p  = single.st?.map(i => decodePolyline(i.p))


  const handleAdd = (item) => {
    dispatch(cancelRouteStop(single.st.map(i => i.id)))
    setSelectedMarker(item);
    setOpen(true);
    dispatch(setOldTime(single.tt[0]?.t));
  };


  const handleRemove = (item) => {
    dispatch(cancelRouteStop(single.st.map(i => i.id)))
    setSelectedMarker(item);
    setRemove(true);
  };

  const handleChange = (item) => {
    dispatch(cancelRouteStop(single.st.map(i => i.id)))
    setSelectedMarker(item);
    setChange(true);
  };

  // console.log('single', single);

  useEffect(() => {
    dispatch(fetchStopsRequest());
    dispatch(cleanSelectedStop());
    dispatch(showAutocomplete());
    dispatch(hideScheduleObjectButton());
  }, []);

  const closeRemove = () => {
    setRemove(false);
  };
  const closeChange = () => {
    setChange(false);
  };

  const closeAdd = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(fetchStopsRequest());
    dispatch(cleanSelectedStop());
    // dispatch(setOldTime(single.tt[0]?.t));
    // console.log("SCHED", single.tt[0]?.t)
  }, []);

  const onStopClick = (id) => {
    setShowBtn(id);
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyD8VJHZ2vIQNxAZZ1hf0vKnEa3KjmXM1Pg',
  });

  return (
    <Grid container>
      <Grid
        item
        width={'30%'}
        sx={{ overflow: 'auto', height: '80vh', backgroundColor: 'whitesmoke' }}
      >
        {!isLoaded ? (
          <Preloader />
        ) : (
          <div>
            {singleST &&
              singleST.map((stops, index) => (
                <div className={classes.prettyStopRow} key={index}>
                  <div className={classes.left}>
                    <div className={classes.index}>{index + 1}</div>
                    <div
                      className={`${classes.prettyBorder} ${
                        index === 0 && classes.first
                      }`}
                    >
                      <div
                        className={`${classes.circle} ${
                          index === 0 && classes.first
                        }`}
                      ></div>
                    </div>
                  </div>
                  <div
                    className={classes.right}
                    onClick={() => onStopClick(stops.id)}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <span>{stops.n}</span>
                      <span
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        {/*<IconButton aria-label="edit"*/}
                        {/*            onClick={() => {*/}

                        {/*            }}*/}
                        {/*>*/}
                        {showBtn === stops.id && (
                          <>
                            <Tooltip title="Добавить" arrow>
                              <AddCircleIcon
                                sx={style}
                                onClick={() => handleAdd(stops)}
                              />
                            </Tooltip>
                            <Tooltip title="Заменить" arrow>
                              <ChangeCircleIcon
                                sx={style}
                                onClick={() => handleChange(stops)}
                              />
                            </Tooltip>
                            <Tooltip title="Удалить" arrow>
                              <DeleteIcon
                                sx={style}
                                onClick={() => handleRemove(stops)}
                              />
                            </Tooltip>
                          </>
                        )}
                        {/*</IconButton>*/}
                      </span>
                    </div>
                    <p className={classes.cordination}>{stops.d}</p>
                  </div>
                </div>
              ))}
          </div>
        )}
      </Grid>
      <Grid item flexGrow={1}>
        <div className={classes.container} style={{ position: 'relative' }}>
          {singleST && p && (
            <Map
              singleST={singleST}
              single={p}
              zoom={12.3}
              center={{ lat: 40.5283, lng: 72.7985 }}
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyD8VJHZ2vIQNxAZZ1hf0vKnEa3KjmXM1Pg"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `80vh` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          )}
        </div>
      </Grid>
      {open && (
        <AddModal
          open={open}
          onClose={closeAdd}
          name={selectedMarker.n}
          ids={selectedMarker.i}
          cord={selectedMarker.p}
        />
      )}

      {change && (
        <ChangeModal
          change={change}
          onClose={closeChange}
          name={selectedMarker.n}
          ids={selectedMarker.i}
          cord={selectedMarker.p}
        />
      )}
      {remove && (
        <RemoveModal
          remove={remove}
          onClose={closeRemove}
          name={selectedMarker.n}
          ids={selectedMarker.i}
          idx={selectedMarker.id}
        />
      )}
    </Grid>
  );
};

export default StopsTab;


