import React, {useEffect} from 'react';
import {Backdrop, Box, Button, Card, CardContent, Fade, Modal} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AutocompleteAddRoutes from "./AutocompleteAddRoutes";
import {ColorActionButton, ColorButton} from "./style";
import TextField from "@mui/material/TextField";
import {useDispatch, useSelector} from "react-redux";
import {deleteRouteById, updateRoutesStop} from "../../../store/sagas/routesSagas";
import {useParams} from "react-router-dom";
import {
    deletedRoutesSchedule,
    deleteRouteStop,
    fetchSingleRoutesRequest,
    saveDeletedStop,
    cleanDeleteData, cancelRouteStop, cancelRouteSchedule, putRouteStops, putRouteRequest, cleanTemp, setConfirm,
} from '../../../store/actions/RoutesActions/routesActions';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '500px',
  height: '200px',
  backgroundColor: 'background.paper',
};

const RemoveConfirm = ({ remove, onClose, name, ids, idx }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const data = useSelector((state) => state.routes.deletedStop);
  const single = useSelector((state) => state.routes.singleRoutes);

  const handleSubmit = () => {
    dispatch(saveDeletedStop());
    dispatch(putRouteRequest({ id, data }));
    dispatch(setConfirm())
  };

    const handleCancel = () => {
        dispatch(cancelRouteStop(single.st.map(i => i.id)))
        dispatch(cancelRouteSchedule(single.tt[0]?.t ))
        dispatch(setConfirm())
    }

  return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={remove}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
            >
              <Fade in={remove}>
                <Card sx={style} variant="outlined">
                  <Box
                      style={{
                        fontWeight: 'bold',
                        padding: '15px',
                        backgroundColor: 'blue',
                        color: 'white',
                      }}
                  >
                    {/*{name}*/}
                  </Box>
                  <Box style={{ p: 2 }}>
                    <CardContent>
                      <Typography style={{ marginBottom: '20px' }}>
                        Вы действительно хотите удалить эту остановку?
                      </Typography>
                    </CardContent>
                    {/*<TextField*/}
                    {/*    sx={{width:"300px", marginBottom:"10px"}}*/}
                    {/*    id="outlined-helperText"*/}
                    {/*    label="Найти остановку"*/}
                    {/*    defaultValue="Введите назваие остановки"*/}
                    {/*/>*/}
                    <Box sx={{ pl: 18 }}>
                      <Button
                          color="primary"
                          onClick={handleCancel}
                          variant="outlined"
                          sx={{ mr: '8px' }}
                      >
                        Отменить
                      </Button>
                      <Button color="primary" onClick={handleSubmit} variant="outlined">
                        Удалить
                      </Button>
                    </Box>
                  </Box>
                </Card>
              </Fade>
            </Modal>
  );
};

export default RemoveConfirm;