import React, {useEffect, useState} from 'react';
import {Backdrop, Box, Button, Card, CardContent, Checkbox, Fade, Modal, Tooltip} from "@mui/material";
import Grid from "@mui/material/Grid";
import {useDispatch, useSelector} from "react-redux";
import {useStyles} from "./popupStyle";
import { saveNewRoute} from "../../store/actions/RoutesActions/routesActions";
import DirectionsBusFilledIcon from "@mui/icons-material/DirectionsBusFilled";
import {blue, green, yellow} from "../../colors";
import DirectionsTransitIcon from "@mui/icons-material/DirectionsTransit";
import LocalTaxiIcon from "@mui/icons-material/LocalTaxi";
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import {
    addRouteToState,
    cleanAddPopup,
    setAddPopup,
    setTransportType
} from "../../store/actions/RoutesActions/createRoutesActions";
import {useNavigate} from "react-router-dom";
 import {cleanSaveData} from '../../store/actions/RoutesActions/createRoutesActions';
import AutocompleteCreateRoutes from "./AutocompleteCreateRoutes";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '800px',
  height: '550px',
  backgroundColor: 'background.paper',
};

const transports = {
    fontSize: "22px",
}

const AddPopup = ({add, stops}) => {
    const transportType = useSelector((state) => state.createRoutes.transportType)
    const saveRoutes = useSelector((state) => state.createRoutes.saveStore)
    const styles = useStyles()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [type, setType] = useState(0)
    const [circle, setCircle] = useState(false)

    const saveRoute = (route) => {
        // dispatch(saveNewRoute(route))
        dispatch(addRouteToState(route))
    }

    const handleNavigate = () => {
        if(transportType.length === 0){
            //("Остановоки должны не менее 2x или " +
            alert("Выберите тип транспорта")
        } else {
            dispatch(cleanAddPopup())
            navigate('create')
        }
    }

    useEffect(() => {
        dispatch(cleanSaveData())
    },[])


    const handleChange = () => {
        dispatch(cleanAddPopup())
        dispatch(cleanSaveData());
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={add}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={add}>
                <Card sx={style} variant="outlined">
                    <Box style={{fontWeight:"bold", padding:"15px", backgroundColor: "blue", color:"white"}} >Остановки маршрута</Box>
                    <Box style={{p:2}}>
                        <CardContent className={styles.listBox}>
                          <Grid className={styles.horizontalList}>
                              <AutocompleteCreateRoutes/>
                          </Grid>
                            <Grid className={styles.horizontal}>{saveRoutes && saveRoutes.map((item) => (
                                <div className={styles.row}>
                                    <div style={{margin:"10px 0"}}>
                                        <p>{item.n}</p>
                                        <i style={{fontSize: "12px"}}>{item.p[0].x} , {item.p[0].y}</i>
                                    </div>
                                </div>
                            ))}</Grid>
                            <Grid container sx={{position:"absolute", left: 0, bottom: 0, justifyContent:"space-around",
                                backgroundColor: 'lightgrey', p: "10px"}}>
                                <Grid item width={"65%"} justifyContent={"space-evenly"} alignItems={"center"}>
                                    <Tooltip title="Автобусы" arrow>
                                        <Checkbox
                                            name="bus"
                                            checked={type.bus}
                                            onChange={() => dispatch(setTransportType(1))}
                                            inputProps={{'aria-label': 'controlled'}}
                                            icon={<DirectionsBusFilledIcon
                                                sx={{...transports, color: "darkgrey"}}/>}
                                            checkedIcon={<DirectionsBusFilledIcon sx={{...transports, color: green}}/>}
                                            sx={{backgroundColor: "whitesmoke",marginLeft: "15px", "&:hover": {backgroundColor: 'lightgrey'}}}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Тролейбусы" arrow>
                                        <Checkbox
                                            name="trolleybus"
                                            checked={type.trolleybus}
                                            onChange={() => dispatch(setTransportType(2))}
                                            inputProps={{'aria-label': 'controlled'}}
                                            icon={<DirectionsTransitIcon
                                                sx={{...transports, color: "darkgrey"}}/>}
                                            checkedIcon={<DirectionsTransitIcon sx={{...transports, color: blue}}/>}
                                            sx={{backgroundColor: "whitesmoke", marginLeft: "15px", "&:hover": {backgroundColor: 'lightgrey',}}}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Маршрутные такси" arrow>
                                        <Checkbox
                                            name='taxi'
                                            checked={type.taxi}
                                            onChange={() => dispatch(setTransportType(3))}
                                            inputProps={{'aria-label': 'controlled'}}
                                            icon={<LocalTaxiIcon
                                                sx={{...transports, color: "darkgrey"}}/>}
                                            checkedIcon={<LocalTaxiIcon sx={{...transports, color: yellow}}/>}
                                            sx={{backgroundColor: "whitesmoke", marginLeft: "15px", "&:hover": {backgroundColor: 'lightgrey'}}}
                                        />
                                    </Tooltip>
                                    {/*<Checkbox*/}
                                    {/*    type="checkbox"*/}
                                    {/*    name="circle"*/}
                                    {/*    id="5"*/}
                                    {/*    handleClick={() => handleCircle()}*/}
                                    {/*    isChecked={circle}*/}
                                    {/*/>*/}
                                    {/*<span style={{marginLeft:"5px"}}>Кольцевой маршрут</span>*/}
                                </Grid>
                                 <Grid>
                                <Button color="primary"
                                        onClick={handleChange}
                                        variant="outlined"
                                        sx={{mr:"8px"}}>Отменить</Button>
                                <Button color="primary"
                                        onClick={handleNavigate}

                                        variant="outlined">Сохранить</Button>
                                 </Grid>
                            </Grid>
                        </CardContent>
                    </Box>
                </Card>
            </Fade>
        </Modal>
    );
};

export default AddPopup;