import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {apiURL} from "../../config";
import {toast} from "react-toastify";
import {
  clearRides,
  fetchRides,
  setDelayed,
  setOutrun,
  setRidesByID,
  setStatistic,
  singleDelayed,
  singleOutRun,
  switchGeneral,
  switchSingle,
  switchStatistic,
} from '../actions/RoutesActions/ridesActions';
import axiosApi from '../../axiosApi';

export const fetchRidesRequest = createAsyncThunk(
  'get/rides',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosApi.get('rides/tracking/');
      dispatch(clearRides());
      dispatch(fetchRides(response.data));
      dispatch(setDelayed(response.data));
      dispatch(setOutrun(response.data));
    } catch (error) {
      toast.error('Не удалось загрузить');
    }
  }
);

export const fetchRidesStatistic = createAsyncThunk(
  'get/rides/statistic',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosApi.get('rides/');
      dispatch(setStatistic(response.data));
    } catch (error) {
      toast.error('Не удалось загрузить');
    }
  }
);

export const fetchRidesByID = createAsyncThunk(
  'get/rides/statistic',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosApi.get(`/ride/by/${id}/`);
      dispatch(switchGeneral());
      // dispatch(switchSingle())
      dispatch(setRidesByID(response.data));
      dispatch(singleDelayed(id));
      dispatch(singleOutRun(id))
    } catch (error) {
      toast.error('Не удалось загрузить');
    }
  }
);




