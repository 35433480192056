import {createSlice} from "@reduxjs/toolkit";
import {singleDelayed} from "../actions/RoutesActions/ridesActions";

const name = 'route';

const initialState = {
    rides: null,
    statistic: null,
    delayed: null,
    delay:  [],
    outrun: [],
    countOutRun: null,
    generalStat: true,
    singleStat: false,
    singleRide: [],
    singleOutRun: [],
    singleDelay: [],
    delaySingle:  [],
    outrunSingle: [],
}

const ridesSlice = createSlice({
        name,
        initialState,
        reducers: {
            switchGeneral(state, action){
             state.generalStat = !state.generalStat
                state.singleStat = !state.singleStat
            },
            switchSingle(state, action){
                state.singleStat = !state.singleStat
                state.generalStat = !state.generalStat
            },
            fetchRides(state, action){
           //fetchRides(state, {payload:{ride, id}}){
                // state.rides[id] = state.rides[ride]
                // state.rides.push({id:id, data: ride})
                state.rides = action.payload
            },
            clearRides(state){
                state.rides = []
            },
            setStatistic(state, action){
                state.statistic = action.payload
            },
            setDelayed(state, action){
                // console.log('sd',action.payload)
                state.delay = Object.entries(action.payload).map(i => Object.values(i[1]).filter(e => e.diff < -5))
                // state.delayed = null
                state.delayed = state.delay.flat(2).length
                // console.log('d', state.delay)
            },
            setOutrun(state, action){
                // console.log('o', action.payload)
                state.outrun = Object.entries(action.payload).map(i => Object.values(i[1]).filter(e => e.diff >= 5))
                state.countOutRun = null
                state.countOutRun = state.outrun.flat(2).length
            },
            setRidesByID(state, action){
                state.singleRide = action.payload
            },
            singleDelayed(state, action){
                Object.entries(state.rides).map(bus=> {
                        const ride = Object.values(bus[1])
                        if(+bus[0] === action.payload) {
                            state.singleDelay = ride
                        }
                        })
                state.delaySingle = state.singleDelay.filter(e => e.diff < -5)

                // state.delayed = null
                // state.singleDelayed = state.delaySingle.length
                // console.log('l',singleDelayed)
                // debugger
                // console.log('d', state.delay)
            },
            singleOutRun(state, action){
                Object.entries(state.rides).map(bus=> {
                    const ride = Object.values(bus[1])
                    if(+bus[0] === action.payload) {
                        state.singleOutRun = ride
                    }
                })
                state.outrunSingle = state.singleOutRun.filter(e => e.diff > 5)

            },
        }
    })

;

export default ridesSlice;