import {createSlice} from "@reduxjs/toolkit";

export const initialState = {
  check: [],
  time: [],
  t: [],
  unit: [],
  saveButton: false,
  checkObj: [],
  checkUnit: false,
  nameUnit: [],
};

const name = 'objects';

const routesObjectSlice = createSlice({
  name,
  initialState,
  reducers: {
    setIsCheckOBj(state, action) {
      state.checkObj.push(action.payload);
    },
    clearIsCheckObj(state, action) {
      state.checkObj = state.checkObj.filter((i) => i !== action.payload);
    },
    setIsCheck(state, action) {
      state.check.push(...action.payload);
    },
    setActiveButton(state) {
      state.saveButton = true;
    },
    cleanActiveButton(state) {
      state.saveButton = false;
    },
    clearIsCheck(state, action) {
      state.check = state.check.filter((i) => i !== action.payload);
    },
    setTimeTable(state, action) {
      state.time.push(action.payload);
    },
    clearCheckUnit(state, action) {
      state.unit = [];
    },

    cleanNameUnit(state, action) {
      const units = action.payload.map((i) => i.id);
      state.nameUnit = state.nameUnit.filter((i) => !units.includes(i.id));
    },
    setNameUnit(state, action) {
      if(state.nameUnit.includes(action.payload)){
       state.nameUnit = state.nameUnit
      } else {
        state.nameUnit.push(...action.payload);
      }
    },
    isCheckUnit(state, action) {
      state.isUnit = !state.isUnit;
    },

    uncheckUnit(state, action) {
      state.checkUnit = false;
    },

    assignCheckUnit(state, action) {
      state.checkUnit = true;
    },

    cleanCheck(state) {
      state.check = [];
      state.nameUnit = [];
    },
  },
});

export default routesObjectSlice;