import {makeStyles} from "@mui/styles";

export const useStyles = makeStyles(theme => ({
    autocomplete: {
        fontSize: "16px",
        border: "1px solid #DFDFDF",
        padding: "10px",
        width: "1330px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
        margin: "5px 10px",
        backgroundColor: "whitesmoke",
        cursor: "pointer"
    },
    streetsBox: {
        padding: "15px",
    },
    routes: {
        display: "flex",
        color: "black",
        width: "1000px"
    },
    option: {
        display: "flex",
        alignItems: "center",

    }

}));