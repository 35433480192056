/* global google */
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
    setPolyline,
    addEmptyPolyline,
    setTemp,
    saveChangeButton,
    setChangeButton,
    cleanPolyline,
    updatePolyline,
    saveUpdatePolyline,
    setOldTime,
    fetchSingleRoutesRequest,
    fetchSingleRoutesSuccess, cancelRouteStop,
} from '../../../store/actions/RoutesActions/routesActions';
import { ColorButton } from './style';
import {
    Circle,
    Polyline,
    GoogleMap,
    InfoWindow,
    Marker,
    MarkerClusterer,
    DirectionsService,
    DirectionsRenderer,
    useGoogleMap,
    withScriptjs,
    withGoogleMap,
} from 'react-google-maps';
import { connect, useDispatch, useSelector } from 'react-redux';
import FlagFill from '../../../assets/images/bus.png';
import { decode, encode } from '@googlemaps/polyline-codec';
import { container } from '../../BusStopsMap/style';
import Preloader from '../../UI/Preloader/Preloader';
import AddModal from './AddModal';
import ChangeModal from './changeModal';
import RemoveModal from './removeModal';
import decodePolyline from 'decode-google-map-polyline';
import { useParams } from 'react-router-dom';
import { fetchSingleRoutesThunk } from '../../../store/sagas/routesSagas';
import RemoveConfirm from './removeConfirm';
import polyline from 'google-polyline';
import ConfirmModal from '../../../containers/SIngleRoute/confirmModal';
import {simplify} from '@turf/turf';

import { Grid } from '@mui/material';

const Map = withScriptjs(
    withGoogleMap((props) => {
        // const {zoom, center, single} = props
        const [openTooltip, setOpenTooltip] = useState('');
        const [selectedMarker, setSelectedMarker] = useState('');
        const single = useSelector((state) => state.routes.singleRoutes);
        const singleTab = useSelector((state) => state.routes.stopTab);
        const singleST = useSelector((state) => state.routes.singleST);
        const confirmAdd = useSelector((state) => state.routes.addConfirm);
        const confirm = useSelector((state) => state.routes.confirm);
        const [open, setOpen] = React.useState(false);
        const [remove, setRemove] = React.useState(false);
        const [change, setChange] = React.useState(false);
        const [direction, setDirection] = useState([]);
        const [isShow, setShow] = useState(false);
        const polylineRef = useRef(null);
        const listenersRef = useRef([]);
        const dispatch = useDispatch();
        const { id } = useParams();

        // console.log(JSON.stringify(single));

        const handleAdd = () => {
            setOpen(!open);
            dispatch(cancelRouteStop(single.st.map((i) => i.id)));
        };
        const handleRemove = () => {
            setRemove(!remove);
            dispatch(cancelRouteStop(single.st.map((i) => i.id)));
        };
        const handleChange = () => {
            setChange(!change);
            dispatch(cancelRouteStop(single.st.map((i) => i.id)));
        };
        const [zooms, setZoom] = useState(12.3);
        const [centers, setCenter] = useState({
            lat: 40.5283,
            lng: 72.7985,
        });
        const [editable, setEditable] = useState(false);
        var p;
        const [polylinePaths, setPolylinePaths] = useState([]);
        const mapRef = useRef(null);

        // useEffect(() => dispatch(fetchSingleRoutesSuccess(single)),[single])

        useEffect(() => {
            p = single.st?.map((i) => decodePolyline(i.p));
            setPolylinePaths(p);
        }, [single]);

        useEffect(() => {
            dispatch(setOldTime(single.tt[0]?.t));
        }, []);

        const handleZoomChanged = () => {
            const currentZoom = mapRef.current.getZoom();
            setZoom(currentZoom);
            setEditable(currentZoom >= 16);
        };

        const handleCenterChanged = () => {
            const currentCenter = mapRef.current.getCenter().toJSON();
            setCenter(currentCenter);
        };

        useMemo(() => {
            dispatch(setChangeButton());
            let waypointsArray = [];
            let totalWaypoints = singleTab;
            // var directionsService;
            var l = null;
            var c = null;
            var e = null;
            var r = null;

            if (totalWaypoints.length > 0) {
                // divide for multiple reguest
                for (let i = 0; i < totalWaypoints.length; i += 20) {
                    waypointsArray.push(totalWaypoints.slice(i, i + 20));
                }

                const g = window.google;
                const promises = [];

                waypointsArray.forEach((item) => {
                    var directionsService = new g.maps.DirectionsService();
                    let waypoints = item;
                    l = waypoints[0].p[0].y.toString();
                    c = waypoints[0].p[0].x.toString();
                    e = waypoints[waypoints.length - 1].p[0].y.toString();
                    r = waypoints[waypoints.length - 1].p[0].x.toString();
                    const waypts = [];

                    // slice first end location, because they have request
                    waypoints.slice(0, waypoints.length - 1).forEach((stop) => {
                        const obj = {
                            location: `${stop.p[0].y}, ${stop.p[0].x}`,
                        };
                        waypts.push(obj);
                    });

                    // console.log('w', waypts);

                    const promise = new Promise((resolve, reject) => {
                        directionsService.route(
                            {
                                origin: new g.maps.LatLng(l, c),
                                destination: new g.maps.LatLng(e, r),
                                waypoints: waypts,
                                travelMode: 'DRIVING',
                                // provideRouteAlternatives: true,
                            },
                            (result, status) => {
                                if (status === g.maps.DirectionsStatus.OK) {
                                    resolve(result);
                                } else {
                                    reject(`error fetching directions ${result}`);
                                }
                            }
                        );
                    });

                    promises.push(promise);
                });

                Promise.all(promises)
                    .then((results) => {
                        // console.log('r', results);
                        setDirection(results);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        }, [singleTab]);

        useMemo(() => {
            const arr = [];
            const decPoly = direction
                ?.map((item) =>
                    item.routes
                        .map((route) =>
                            route.legs.map((leg) =>
                                leg.steps.map((step) => step.polyline.points).flat(2)
                            )
                        )
                        .flat(1)
                )
                .flat(1);
            // console.log('dec', decPoly);
            // get decPoly some encode path. Decode they and concat they encoding
            for (let i = 0; i < decPoly.length; i++) {
                const r = decPoly[i].map((i) => decode(i)).flat(1);
                // const poly = []
                // r.forEach((stop) => {
                //     const obj = {
                //         lat: stop[0], lng: stop[1]
                //     };
                //     poly.push(obj);
                // });

// Define a tolerance value
//                 const tolerance = 30.000;

// Create a new array to store the simplified waypoints
//                 const s = [];

// Loop through the original waypoints array
//                 for (let i = 0; i < poly.length - 1; i++) {
//                     const distance = google.maps.geometry.spherical.computeDistanceBetween(
//                         new google.maps.LatLng(poly[i]),
//                         new google.maps.LatLng(poly[i + 1])
//                     );
//                     console.log('d', distance)
//                     // If the distance between two neighboring points is greater than the tolerance value, add the first point to the simplifiedWaypoints array
//                     if (distance > 20) {
//                         s.push(poly[i]);
//                     }
//                 }

// Add the last point to the simplifiedWaypoints array
//                 s.push(poly[poly.length - 1]);
                const dr = encode(r, 5);
                console.log('dr', r);
                arr.push(dr);
            }

            dispatch(setPolyline(arr));
        }, [direction]);

        const onEdit = useCallback((index, newPath) => {
            console.log('n', newPath);
            // setPolylinePaths((prevPaths) => {
            //     const newPaths = [...prevPaths];
            //     newPaths[index] = newPath;
            //     return newPaths;
            // });
            const encoded = encode(newPath, 5);
            dispatch(updatePolyline({ index, encoded }));
            // dispatch(addNewSchedule())
            dispatch(saveUpdatePolyline());
        }, []);

        const polylineRefs = useRef(Array(p?.length).fill(null));


        // const onEdit = useCallback(() => {
        //
        //     const nextPath = polylineRef.current
        //         .getPath()
        //         .getArray()
        //         .map((latLng) => latLng.toJSON());
        //     console.log('n', nextPath)
        //     // setPath(nextPath);
        //     nextPath.forEach(stop=> {
        //
        //
        //         const pol = [
        //             stop.lat, stop.lng
        //         ]
        //
        //         trans.push(pol);
        //
        //     })
        //     const dec = encode(trans)
        //     // console.log(dec)
        //
        //     // }
        // }, [setPath]);

        // Bind refs to current Polyline and listeners
        // const onLoad = useCallback(
        //     (polyline) => {
        //         polylineRefs.current = polyline;
        //         const path = polyline.getPath();
        //         listenersRef.current.push(
        //             path.addListener("set_at", onEdit),
        //             path.addListener("insert_at", onEdit),
        //             path.addListener("remove_at", onEdit)
        //         );
        //     },
        //     [onEdit]
        // );

        // Clean up refs
        const onUnmount = useCallback(() => {
            listenersRef.current.forEach((lis) => lis.remove());
            polylineRef.current = null;
        }, []);

        return (
            <>
                <GoogleMap
                    ref={mapRef}
                    center={centers}
                    zoom={zooms}
                    onZoomChanged={handleZoomChanged}
                >
                    <div>
                        {isShow &&
                            direction.map((i) => (
                                <DirectionsRenderer
                                    directions={i}
                                    options={{ draggable: true, suppressMarkers: true }}
                                />
                            ))}
                        {polylinePaths.length > 0 &&
                            polylinePaths.map((item, index) => (
                                <Polyline
                                    path={item}
                                    onUnmount={onUnmount}
                                    visible={true}
                                    editable={editable}
                                    draggable={false}
                                    ref={(ref) => (polylineRefs.current[index] = ref)}
                                    onMouseUp={() => {
                                        const polylineRef = polylineRefs.current[index];
                                        if (polylineRef) {
                                            const newPath = polylineRef
                                                .getPath()
                                                .getArray()
                                                .map((latLng) => latLng.toJSON());
                                            onEdit(index, newPath);
                                        }
                                    }}
                                />
                            ))}
                    </div>
                    {singleST &&
                        singleST.map((item, i) => (
                            <div key={i}>
                                <Marker
                                    position={{
                                        lat: item.p[0].y,
                                        lng: item.p[0].x,
                                    }}
                                    label={(i + 1).toString()}
                                    onMouseDown={() => {
                                        setSelectedMarker(item);
                                        setOpenTooltip('');
                                    }}
                                    onMouseOver={() => {
                                        setOpenTooltip(item);
                                    }}
                                    onMouseOut={() => {
                                        setOpenTooltip(false);
                                    }}
                                />
                                {openTooltip && (
                                    <InfoWindow
                                        options={{
                                            pixelOffset: new window.google.maps.Size(0, -40),
                                        }}
                                        position={{
                                            lat: openTooltip.p[0].y,
                                            lng: openTooltip.p[0].x,
                                        }}
                                        onCloseClick={() => setOpenTooltip(false)}
                                    >
                                        <h4>
                                            {openTooltip.n}
                                            <br />
                                            {openTooltip.d}
                                        </h4>
                                    </InfoWindow>
                                )}
                                {selectedMarker && (
                                    <InfoWindow
                                        options={{
                                            pixelOffset: new window.google.maps.Size(0, -40),
                                        }}
                                        onCloseClick={() => setSelectedMarker(false)}
                                        position={{
                                            lat: selectedMarker.p[0].y,
                                            lng: selectedMarker.p[0].x,
                                        }}
                                        className="mb-[45px]"
                                    >
                                        <div>
                                            <ColorButton onClick={handleChange}>Заменить</ColorButton>
                                            <ColorButton onClick={handleAdd}>Добавить</ColorButton>
                                            <ColorButton onClick={handleRemove}>Изьять</ColorButton>
                                        </div>
                                    </InfoWindow>
                                )}
                                {open && (
                                    <AddModal
                                        open={open}
                                        onClose={handleAdd}
                                        name={selectedMarker.n}
                                        ids={selectedMarker.i}
                                        cord={selectedMarker.p}
                                    />
                                )}
                                {change && (
                                    <ChangeModal
                                        change={change}
                                        onClose={handleChange}
                                        name={selectedMarker.n}
                                        ids={selectedMarker.i}
                                        cord={selectedMarker.p}
                                    />
                                )}
                                {remove && (
                                    <RemoveModal
                                        remove={remove}
                                        onClose={handleRemove}
                                        name={selectedMarker.n}
                                        ids={selectedMarker.i}
                                        cord={selectedMarker.p}
                                    />
                                )}
                                {confirm && <RemoveConfirm remove={confirm} />}
                                {confirmAdd && <ConfirmModal remove={confirmAdd} id={id} />}
                            </div>
                        ))}
                </GoogleMap>
            </>
        );
    })
);

export default React.memo(Map);



