import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveUpdateTime,
  setNewTime,
  setUpdateTime,
} from '../../../store/actions/RoutesActions/routesActions';
import { setActiveSchedulle } from '../../../store/actions/RoutesActions/routesActions';

const TimeField = ({ time, idx }) => {
  const single = useSelector((state) => state.routes.singleRoutes);
  const formatTime = (seconds) => {
    if (isNaN(seconds)) {
      return '00:00';
    }
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours().toString();
    const mm = date.getUTCMinutes().toString().padStart(2, '0');
    return `${hh}:${mm}`;
  };

  const dispatch = useDispatch();
  const [value, setValue] = React.useState(formatTime(time));

  const onChange = (event) => {
    setValue(event.target.value);
    dispatch(setActiveSchedulle());
  };

  const onBlur = (event) => {
    const val = event.target.value;
    const seconds = Math.max(0, getSecondsFromHHMM(val));
    dispatch(setNewTime({ idx: idx, time: seconds }));
    // const obj = { idx: seconds };
    // dispatch(setUpdateTime(seconds));
    setValue(formatTime(seconds));
    dispatch(saveUpdateTime(single));
  };

  function getSecondsFromHHMM(value) {
    const [str1, str2] = value.split(':');

    const val1 = Number(str1);
    const val2 = Number(str2);

    if (!isNaN(val1) && !isNaN(val2)) {
      return val1 * 60 * 60 + val2 * 60;
    }

    return 0;
  }

  return (
    <div
      style={{
        border: '1px solid lightgrey',
        height: '65px',
        width: '90px',
        padding: '20px 20px',
      }}
    >
      <input type="text" onChange={onChange} onBlur={onBlur} value={value} />
    </div>
  );
};

export default TimeField;