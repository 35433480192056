import React, {useEffect, useState} from 'react';
import {Grid, IconButton, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import {blue, yellow} from "../../colors";
import {BusNumberWrapper} from "../../assets/styles/trackingWrapper/trackingWrapper";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useNavigate, useParams} from "react-router-dom";
import {routes, singleRoute} from "../../paths";
import Switch from "@mui/material/Switch";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {styled} from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import StopsTab from "../../components/RoutesTabPanels/StopsTab/StopsTab";
import ScheduleTab from "../../components/RoutesTabPanels/ScheduleTab/ScheduleTab";
import ObjectsTab from "../../components/RoutesTabPanels/ObjectsTab/ObjectsTab";
import {useDispatch, useSelector} from "react-redux";
import {ColorButton} from "../../components/RoutesTabPanels/StopsTab/style";
import {
  assignGroupsToRoutes,
  assignUnitsToRoutes,
  assignUnitsToRouteSuccess,
  cleanActiveRoute,
  clearAssignUnits,
  clearSingleRoutes,
  fetchRoutesGroupsRequest,
  fetchRoutesStopsRequest,
  fetchRoutesUnitsRequest,
  fetchSingleRoutesRequest,
  groupsAndUnitsClean,
  openAssignSaveButton,
  putRouteRequest,
  routesGroupsAndUnitsClean,
  saveUpdateTime,
  setActiveRoute,
  setGlobalId,
  showUnitsCount,
  singleRouteST,
} from '../../store/actions/RoutesActions/routesActions';
import {
  assignUnitsToRoute,
  fetchSingleRoutesThunk,
  putTurnRoute,
  updateTimeTable,
} from '../../store/sagas/routesSagas';
import { makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import DeletePopup from '../../components/AutocompleteRoutes/deletePopup';
import {
  assignCheckUnit,
  cleanCheck,
  setIsCheck,
  setNameUnit,
} from '../../store/actions/RoutesActions/routesObjectsActions';
import DeleteModal from "./deleteModal";
import AddModal from "../../components/RoutesTabPanels/StopsTab/AddModal";
import ConfirmModal from "./confirmModal";

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -15,
    top: 1,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    backgroundColor: blue,
    width: '25px',
  },
}));

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid lightgrey',
    marginBottom: '20px',
    cursor: 'pointer',
  },
  container2: {
    width: '400px',
    backgroundColor: 'white',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid lightgrey',
    marginBottom: '20px',
    cursor: 'pointer',
  },
}));

const SingleRoute = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const style = useStyles();
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const [value, setValue] = React.useState('1');
  const [uCount, setUCount] = React.useState(0);
  const [isDeletePopup, setDeletePopup] = useState(false);
  const [addConfirm, setAddConfirm] = useState(false);
  const groups = useSelector((state) => state.routes.groups);
  const units = useSelector((state) => state.routes.units);
  const assign = useSelector((state) => state.routes.assign);
  const globalId = useSelector((state) => state.routes.globalID);
  const single = useSelector((state) => state.routes.singleRoutes);
  const data = useSelector((state) => state.routes.saveTime);
  const unit = useSelector((state) => state.routes.assignUnits);
  const group = useSelector((state) => state.routes.assignGroups);
  const checked = useSelector((state) => state.routes.activeRoute);
  const streets = useSelector((state) => state.routes.stops);
   const confirmAdd = useSelector((state) => state.routes.addConfirm);
  const stop = useSelector((state) => state.routes.singleRoutes);
  const saveSchedulleButton = useSelector(
    (state) => state.routes.scheduleButton
  );
  const changeData = useSelector((state) => state.routes.changeStop);
  const saveChangeMap = useSelector((state) => state.routes.changeButton);
  const saveObjectButton = useSelector((state) => state.routes.objectButton);
  const changedData = useSelector((state) => state.routes.updatePolyline);
  const [selectedRoute, setSelectedRoute] = useState([]);
  // const time = useSelector((state) => state.objects.time)
  const [selectedID, setSelectedID] = useState([]);
  const [active, setActive] = useState([]);
  const isCheck = useSelector((state) => state.objects.check);
  const [isCheckAll, setIsCheckAll] = useState(false);

  useEffect(() => {
    dispatch(routesGroupsAndUnitsClean());
    dispatch(cleanCheck());
    dispatch(clearSingleRoutes());
    dispatch(fetchRoutesStopsRequest())
  }, []);

  const onClose = () => setDeletePopup(!isDeletePopup);

  const onAddConfirm = () => setAddConfirm(true);

  const handleDelete = (item) => {
    setDeletePopup(!isDeletePopup);
    setSelectedRoute(item);
    setSelectedID(item.id);
  };

  // useEffect(() => {
  //   dispatch(routesGroupsAndUnitsClean());
  //   dispatch(cleanCheck());
  // }, []);

  useEffect(() => {
    dispatch(fetchSingleRoutesThunk(id));
  }, []);

  useEffect(() => {
    dispatch(fetchRoutesStopsRequest());
    dispatch(singleRouteST());
  }, []);

  useEffect(() => {
    dispatch(singleRouteST());
  }, [stop]);

  // useEffect(() => {
  //     const active = streets.filter((i) => i.a === true);
  //     dispatch(setActiveRoute(active.map((i) => i.id)));
  // }, [streets]);

  const handleSelectAll = (id) => {
    const unites = units
      .filter((unit) => unit.groupid === id[0])
      .map((item) => item.id);
    const groupes = groups
      .filter((group) => group.id === id[0])
      .map((item) => item.id);
    const asUnits = units.filter((unit) => unit.groupid === id[0]);
    if (groupes.length > 0) {
      dispatch(assignGroupsToRoutes(groupes));
    }
    if (unites.length > 0) {
      dispatch(setNameUnit(asUnits));
      dispatch(assignUnitsToRoutes(unites));
      dispatch(assignUnitsToRouteSuccess(unites));
    }
    dispatch(setIsCheck(groupes));
    dispatch(assignCheckUnit());
    // console.log(unites);
    // console.log(groupes);
  };

  useEffect(() => {
    setActiveGroup();
  }, [single]);

  const setActiveGroup = () => {
    if (single.ug) {
      handleSelectAll(single.ug?.map((i) => i.id));
    }
  };

  // console.log(single.st)
  const SubmitChangeMap = () => {
    dispatch(putRouteRequest({ data: changedData, id }));
  };

  const SubmitSceduleTime = () => {
    dispatch(saveUpdateTime(stop));
    dispatch(putRouteRequest({ data, id }));
  };

  const SaveAssignUnitsToRoute = () => {
    dispatch(
      assignUnitsToRoute({ route: [id], unit: [...unit], group: [...group] })
    );
  };

  const onCancel = () => {
    dispatch();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTurn = (item) => {
    const id = item.id;
    const active = !item.a;
    dispatch(putTurnRoute({ id, active }));
    if (checked.includes(item.id)) {
      dispatch(cleanActiveRoute(item.id));
    } else {
      dispatch(setActiveRoute([item.id]));
    }
  };

  return (
    <Grid sx={{ backgroundColor: 'whitesmoke' }} spacing={1}>
      <Box
        sx={{
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box className={style.container}>
          <IconButton variant="outlined" onClick={() => navigate(routes)}>
            <ArrowBackIcon />
          </IconButton>
          <DirectionsBusIcon
            sx={{
              color: yellow,
              fontSize: '30px',
              width: '60px',
            }}
          />
          <BusNumberWrapper>
            <p>{single && single.n}</p>
          </BusNumberWrapper>
          <Typography variant={'h5'} sx={{ paddingBottom: '5px' }}>
            <p>{single.st && single.st[0].id.n + ' - ' + single.st[1].id.n} </p>
          </Typography>
        </Box>
        <Box className={style.container}>
          {/* {!saveSchedulleButton ||
              (!saveObjectButton && ( */}
          <>
            <Switch
              checked={checked.includes(single.id)}
              onClick={() => handleTurn(single)}
              onChange={() => setActive(single.a === false ? true : false)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <DeleteIcon
              style={{ width: '30px', height: '30px' }}
              onClick={() => handleDelete(single)}
            />
          </>
          {/* ))} */}
          {saveChangeMap && (
            <div style={{ display: 'flex ' }}>
              <ColorButton
                style={{ margin: '5px' }}
                variant={'contained'}
                onClick={onCancel}
              >
                Отменить
              </ColorButton>
              <ColorButton
                style={{ margin: '5px' }}
                variant={'contained'}
                onClick={SubmitChangeMap}
              >
                Сохранить
              </ColorButton>
            </div>
          )}
          {saveObjectButton && (
            <div style={{ display: 'flex ' }}>
              <ColorButton
                style={{ margin: '5px' }}
                variant={'outlined'}
                onClick={onCancel}
              >
                Отменить
              </ColorButton>
              <ColorButton
                style={{ margin: '5px' }}
                variant={'outlined'}
                onClick={SaveAssignUnitsToRoute}
              >
                Сохранить
              </ColorButton>
            </div>
          )}
          {saveSchedulleButton && (
            <div style={{ display: 'flex ' }}>
              <ColorButton
                style={{ margin: '5px' }}
                variant={'contained'}
                onClick={onCancel}
              >
                Отменить
              </ColorButton>
              <ColorButton
                style={{ margin: '5px' }}
                variant={'contained'}
                onClick={SubmitSceduleTime}
              >
                Сохранить
              </ColorButton>
            </div>
          )}
        </Box>
      </Box>
      <Grid item>
        <Box
          sx={{
            width: '100%',
            typography: 'body1',
            backgroundColor: 'white',
          }}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                aria-label="table_report"
                TabIndicatorProps={{
                  sx: {
                    backgroundColor: blue,
                  },
                }}
              >
                <Tab
                  sx={{ paddingRight: '35px' }}
                  label={
                    <StyledBadge
                      badgeContent={single.st && single.st.length}
                      color="primary"
                    >
                      Остановки{' '}
                    </StyledBadge>
                  }
                  value="1"
                />
                <Tab
                  sx={{ paddingRight: '35px' }}
                  label={
                    <StyledBadge badgeContent={1} color="primary">
                      Расписание{' '}
                    </StyledBadge>
                  }
                  value="2"
                />
                <Tab
                  sx={{ paddingRight: '35px' }}
                  label={
                    <StyledBadge badgeContent={unit.length} color="primary">
                      Объекты{' '}
                    </StyledBadge>
                  }
                  value="3"
                />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: '10px' }}>
              <StopsTab />
            </TabPanel>
            <TabPanel value="2" sx={{ padding: '10px' }}>
              <ScheduleTab />
            </TabPanel>
            <TabPanel value="3" sx={{ padding: '10px' }}>
              <ObjectsTab />
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
      {isDeletePopup && (
        <DeleteModal
          remove={isDeletePopup}
          onClose={onClose}
          name={selectedRoute}
          id={id}
        />
      )}
      {/*{confirmAdd && (*/}
      {/*    <ConfirmModal*/}
      {/*        remove={addConfirm}*/}
      {/*        onClose={onAddConfirm}*/}
      {/*        id={id}/>*/}
      {/*)}*/}
    </Grid>
  );
};

export default SingleRoute;