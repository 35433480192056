import React from 'react';
import {Backdrop, Box, Button, Card, CardContent, Fade, Modal} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {deleteRoute, deleteRouteById} from "../../store/sagas/routesSagas";
import {
    addConfirm,
    cleanPolyline,
    deleteRouteAtStore,
    putRouteRequest,
    setDeletePopup,
    routesGroupsAndUnitsClean
} from "../../store/actions/RoutesActions/routesActions";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "500px",
    height: "200px",
    backgroundColor: 'background.paper',
}

const ConfirmModal = ({remove, onClose, id}) => {
    const data = useSelector((state) => state.routes.addStop);
    const dispatch = useDispatch()

    const handleAdd = () => {
        console.log(data)
        dispatch(putRouteRequest({ id, data }));
        dispatch(addConfirm())
        dispatch(cleanPolyline());
        dispatch(routesGroupsAndUnitsClean())
    }


    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={remove}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={remove}>
                <Card sx={style} variant="outlined">
                    <Box style={{fontWeight:"bold", padding:"15px", backgroundColor: "blue", color:"white"}} >
                        {/*{name.st[0].id.n + " - " + name.st[1].id.n} */}
                    </Box>
                    <Box style={{p:2}}>
                        <CardContent>
                            <Typography style={{marginBottom:"20px"}}>Вы действительно хотите добавить этот маршрут?</Typography>
                        </CardContent>
                        {/*<TextField*/}
                        {/*    sx={{width:"300px", marginBottom:"10px"}}*/}
                        {/*    id="outlined-helperText"*/}
                        {/*    label="Найти остановку"*/}
                        {/*    defaultValue="Введите назваие остановки"*/}
                        {/*/>*/}
                        <Box sx={{pl:18}}>
                            <Button color="primary"
                                    onClick={() => dispatch()}
                                    variant="outlined"
                                    sx={{mr:"8px"}}>Отменить</Button>
                            <Button color="primary"
                                    onClick={() => handleAdd()}
                                    variant="outlined">Потвердить</Button>
                        </Box>
                    </Box>
                </Card>
            </Fade>
        </Modal>
    );
};

export default ConfirmModal;