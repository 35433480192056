import React, {useEffect, useState} from 'react';
import {Grid, IconButton, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import {blue, yellow} from "../../colors";
import {BusNumberWrapper} from "../../assets/styles/trackingWrapper/trackingWrapper";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useNavigate, useParams} from "react-router-dom";
import {routes} from "../../paths";
import Switch from "@mui/material/Switch";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {styled} from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import CreateStopsTab from "../../components/CreateRoutesTabPanels/createStopsTab/createStopsTab";
import CreateScheduleTab from "../../components/CreateRoutesTabPanels/createScheduleTab/createScheduleTab";
import CreateObjectsTab from "../../components/CreateRoutesTabPanels/createObjectsTab/createObjectsTab";
import {useDispatch, useSelector} from "react-redux";
import {ColorButton} from "../../components/RoutesTabPanels/StopsTab/style";
import {
  cleanActiveRoute,
  clearSingleRoutes,
  fetchRoutesGroupsRequest, fetchRoutesStopsRequest,
  fetchRoutesUnitsRequest,
  fetchSingleRoutesRequest, postNewRouteRequest, saveUpdateTime, setActiveRoute,
} from "../../store/actions/RoutesActions/routesActions";
import {
    assignUnitsToRoute,
    fetchSingleRoutesThunk,
    postRoute,
    putTurnRoute,
    updateTimeTable
} from "../../store/sagas/routesSagas";
import {makeStyles} from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import DeletePopup from "../../components/AutocompleteRoutes/deletePopup";
import {
  saveNewRoute,
  saveRouteName,
  cleanSaveData,
  routesGroupsAndUnitsClean,
  fillEmptyTime,
  setConfirm
} from '../../store/actions/RoutesActions/createRoutesActions';
import CreateConfirmModal from "./createConfirmModal";

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -15,
    top: 1,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    backgroundColor: blue,
    width: '25px',
  },
}));

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid lightgrey',
    marginBottom: '20px',
    cursor: 'pointer',
  },
}));

const CreateSingleRoute = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const style = useStyles();
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const [value, setValue] = React.useState('1');
  const [uCount, setUCount] = React.useState(0);
  const [isDeletePopup, setDeletePopup] = useState(false);
  const assign = useSelector((state) => state.routes.assign);
  const globalId = useSelector((state) => state.routes.globalID);
  const single = useSelector((state) => state.createRoutes.saveStore);
  const time = useSelector((state) => state.createRoutes.oldTimeTable);
  const unit = useSelector((state) => state.createRoutes.assignUnits);
  const group = useSelector((state) => state.createRoutes.assignGroups);
  const checked = useSelector((state) => state.routes.activeRoute);
  const streets = useSelector((state) => state.routes.stops);
  const saveButton = useSelector((state) => state.objects.saveButton);
  const scheduleButton = useSelector(
    (state) => state.routesSchedule.scheduleButton
  );
  const [selectedRoute, setSelectedRoute] = useState([]);
  // const time = useSelector((state) => state.objects.time)
  const [isTime, setTime] = useState(time);
  const [selectedID, setSelectedID] = useState([]);
  const [active, setActive] = useState([]);
  const [name, setName] = useState('');
  const [isConfirmPopup, setConfirmPopup] = useState(false);
  const confirm = useSelector((state) => state.createRoutes.confirm);

  const onCloseConfirm = () => setConfirmPopup(!isConfirmPopup);



  const postNewRoute = () => {
    // if (
    //   routeName.length == 0 ||
    //   unit.length == 0 ||
    //   group.length == 0 ||
    //   single.length !== time.length
    // ) {
    //   alert('Данные не польностью заполнены');
    // } else {
      dispatch(saveNewRoute());
      dispatch(setConfirm())

    // }
  };

  // useEffect(() => {
  //     dispatch(postNewRouteRequest(newRoute))
  // },[newRoute])

  const onClose = () => setDeletePopup(!isDeletePopup);

  const handleDelete = (item) => {
    setDeletePopup(!isDeletePopup);
    setSelectedRoute(item);
    setSelectedID(item.id);
  };

  // useEffect(() => {
  //     dispatch(fetchSingleRoutesThunk(id))
  // },[])

  // useEffect(() => {
  //     dispatch(fetchRoutesStopsRequest())
  // }, []);

  useEffect(() => {
    const active = streets.filter((i) => i.a === true);
    dispatch(setActiveRoute(active.map((i) => i.id)));
  }, [streets]);

  useEffect(() => {}, [single]);

  useEffect(() => {
    dispatch(routesGroupsAndUnitsClean());
  }, []);

  // const SubmitSceduleTime = () => {
  //     dispatch(saveUpdateTime())
  //     dispatch(updateTimeTable({time, id}))
  // }

  const SaveAssignUnitsToRoute = () => {
    dispatch(
      assignUnitsToRoute({ route: [id], unit: [...unit], group: [...group] })
    );
  };

  const handleCancel = () => {
    navigate(routes);
    dispatch(cleanSaveData());
  };

  const submitName = () => {
    dispatch(saveRouteName(name));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTurn = (item) => {
    const id = item.id;
    const active = !item.a;
    dispatch(putTurnRoute({ id, active }));
    if (checked.includes(item.id)) {
      dispatch(cleanActiveRoute(item.id));
    } else {
      dispatch(setActiveRoute([item.id]));
    }
  };

  const navigateBack = () => {
    navigate(routes);
    dispatch(cleanSaveData());
  };

  return (
    <Grid sx={{ backgroundColor: 'whitesmoke' }} spacing={1}>
      <Box
        sx={{
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box className={style.container}>
          <IconButton variant="outlined" onClick={navigateBack}>
            <ArrowBackIcon />
          </IconButton>
          <DirectionsBusIcon
            sx={{
              color: yellow,
              fontSize: '30px',
              width: '60px',
            }}
          />
          <BusNumberWrapper>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              onBlur={() => submitName()}
            />
          </BusNumberWrapper>
          <Typography variant={'h5'} sx={{ paddingBottom: '5px' }}>
            <p>{single && single[0].n + ' - ' + single[1].n} </p>
          </Typography>
        </Box>
        <Box className={style.container}>
          {/*{ !saveButton && !scheduleButton && (*/}
          {/*    <>*/}
          {/*    <Switch*/}
          {/*        checked={checked.includes(single.id)}*/}
          {/*        onClick={() => handleTurn(single)}*/}
          {/*        onChange={() => setActive(single.a === false ? true : false)}*/}
          {/*        inputProps={{'aria-label': 'controlled'}}*/}
          {/*    />*/}
          {/*    <DeleteIcon style={{width: "30px", height: "30px"}} onClick={() => handleDelete(single)}/>*/}
          {/*    </>*/}
          {/*    )*/}
          {/*}*/}
          {/*{saveButton &&*/}
          <div style={{ display: 'flex ' }}>
            <ColorButton
              style={{ margin: '5px' }}
              variant={'contained'}
              onClick={handleCancel}
            >
              Отменить
            </ColorButton>
            <ColorButton
              style={{ margin: '5px' }}
              variant={'contained'}
              onClick={postNewRoute}
            >
              Сохранить
            </ColorButton>
          </div>
          {/*}*/}
          {/*{ scheduleButton &&*/}
          {/*    <div style={{display: "flex "}}>*/}
          {/*        <ColorButton style={{margin: "5px"}} variant={'contained'} onClick={onCancel}>*/}
          {/*            Отменить*/}
          {/*        </ColorButton>*/}
          {/*        <ColorButton style={{margin: "5px"}} variant={'contained'} onClick={SubmitSceduleTime}>*/}
          {/*            Сохранить*/}
          {/*        </ColorButton>*/}
          {/*    </div>*/}
          {/*}*/}
        </Box>
      </Box>
      <Grid item>
        <Box
          sx={{ width: '100%', typography: 'body1', backgroundColor: 'white' }}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                aria-label="table_report"
                TabIndicatorProps={{
                  sx: {
                    backgroundColor: blue,
                  },
                }}
              >
                <Tab
                  sx={{ paddingRight: '35px' }}
                  label={
                    <StyledBadge
                      badgeContent={single && single.length}
                      color="primary"
                    >
                      Остановки{' '}
                    </StyledBadge>
                  }
                  value="1"
                />
                <Tab
                  sx={{ paddingRight: '35px' }}
                  label={
                    <StyledBadge badgeContent={1} color="primary">
                      Расписание{' '}
                    </StyledBadge>
                  }
                  value="2"
                />
                <Tab
                  sx={{ paddingRight: '35px' }}
                  label={
                    <StyledBadge
                      badgeContent={unit && unit.length}
                      color="primary"
                    >
                      Объекты{' '}
                    </StyledBadge>
                  }
                  value="3"
                />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: '10px' }}>
              <CreateStopsTab />
            </TabPanel>
            <TabPanel value="2" sx={{ padding: '10px' }}>
              <CreateScheduleTab />
            </TabPanel>
            <TabPanel value="3" sx={{ padding: '10px' }}>
              <CreateObjectsTab />
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
      {isDeletePopup && (
        <DeletePopup
          remove={isDeletePopup}
          onClose={onClose}
          name={selectedRoute}
          selectedID={selectedID}
        />
      )}
      {confirm && <CreateConfirmModal remove={confirm} onClose={onCloseConfirm}/>}
    </Grid>
  );
};

export default CreateSingleRoute;