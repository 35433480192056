import createRoutesSlice from "../../slices/Routes/createRoutesSlice";


export const {
  setTransportType,
  addRouteToState,
  fetchRoutesStopsRequest,
  fetchRoutesStopsFailure,
  fetchRoutesStopsSuccess,
  fetchSingleRoutesRequest,
  clearSingleRoutes,
  clearAssignUnitRoutes,
  fetchRoutesGroupsFailure,
  fetchRoutesGroupsRequest,
  fetchRoutesGroupsSuccess,
  fetchRoutesUnitsFailure,
  fetchRoutesUnitsRequest,
  fetchRoutesUnitsSuccess,
  addRoutesStopRequest,
  addRoutesStopSuccess,
  addRoutesStopFailure,
  assignUnitsToRouteRequest,
  assignUnitsToRouteSuccess,
  fetchSingleRoutesSuccess,
  fetchSingleRoutesFailure,
  assignGroups,
  assignUnits,
  fetchAssignUnitsRequest,
  fetchAssignUnitsSuccess,
  showUnitsCount,
  openAssignSaveButton,
  setGlobalId,
  setActiveRoute,
  routesGroupsAndUnitsClean,
  cleanActiveRoute,
  setActiveButton,
  saveNewRoute,
  saveUpdateTime,
  deleteRouteById,
  cleanActiveButton,
  searchRoutes,
  setTimeTable,
  setConfirm,
  saveRouteName,
  setPolylineDecode,
  createSetNewTime,
  createSetUpdateTime,
  setOldTime,
  setPolyline,
  setAddPopup,
  cleanAddPopup,
  sliceFistStop,
  addEmptyPolyline,
  cleanSaveData,
  clearAssignUnits,
  setIsCheck,
  clearIsCheck,
  fillEmptyTime
} = createRoutesSlice.actions;