import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import logo from "../../../assets/images/APTOSH_logo.png";
import PreviewIcon from '@mui/icons-material/Preview';
import KeyIcon from '@mui/icons-material/Key';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {
  apiKey,
  busStops,
  dashboard,
  reports,
  rides,
  routes,
  support,
  tracking,
} from '../../../paths';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../store/actions/usersActions';
import { useState } from 'react';
import SupportModal from '../../../containers/Support/SupportPage';
import { setSupport } from '../../../store/actions/RoutesActions/routesActions';
import DeletePopup from '../../AutocompleteRoutes/deletePopup';

const AppToolBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.users.user);
  let location = useLocation();
  let pathname = location.pathname;
  const pages = [
      {name: 'Мониторинг', route: tracking},
    { name: 'Остановки', route: busStops },
    { name: 'Маршруты', route: routes },
    // {name: 'Слежение', route: tracking},
    // {name: 'Отчеты', route: reports}
  ];
  const modal = useSelector((state) => state.routes.support);

  //Временно закоментировала
  // const dropLinks = ['Обзор', 'Настройки пользователя', 'Ключи API', 'Документация', 'Что нового', 'Выход'];
  // const icons = [<PreviewIcon/>, <SettingsIcon/>, <KeyIcon/>, <TextSnippetIcon/>, <TipsAndUpdatesIcon/>,
  //     <ExitToAppIcon/>]

  const dropLinks = [
    { name: 'Обзор', route: dashboard, icon: <PreviewIcon /> },
    { name: 'Ключи API', route: apiKey, icon: <KeyIcon /> },
  ];

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <div style={{ position: 'relative' }}>
      <AppBar position="static" style={{ backgroundColor: '#15416c' }}>
        <Container maxWidth="xxl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              <img
                style={{ width: '100%', height: '50px' }}
                src={logo}
                alt="aptosh-logo"
              />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem
                    key={page.name}
                    onClick={handleCloseNavMenu}
                    sx={{ '.Mui-selected': { color: 'red' } }}
                  >
                    <Typography
                      textAlign="center"
                      component={Link}
                      to={page.route}
                      sx={{ textDecoration: 'none', color: 'black' }}
                    >
                      {page.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              <img
                style={{ width: '140px', height: 'auto' }}
                src={logo}
                alt="aptosh-logo"
              />
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'right',
                marginRight: '10px',
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page.name}
                  onClick={() => navigate(page.route)}
                  sx={{
                    my: 2,
                    display: 'block',
                    color: 'white',
                    borderBottom:
                      page.route === pathname ? '2px solid white' : 'none',
                  }}
                >
                  {page.name}
                </Button>
              ))}
              {/*<Button*/}
              {/*  sx={{*/}
              {/*    my: 2,*/}
              {/*    display: 'block',*/}
              {/*    color: 'white',*/}
              {/*  }}*/}
              {/*  onClick={() => dispatch(setSupport())}*/}
              {/*>*/}
              {/*  Тех.Поддержка*/}
              {/*</Button>*/}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Открыть профиль">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar />{' '}
                  <span
                    style={{
                      fontSize: '13px',
                      color: 'white',
                      marginLeft: '5px',
                    }}
                  >
                    {user && user.username}
                  </span>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {dropLinks.map((link) => (
                  <MenuItem key={link.name} onClick={handleCloseUserMenu}>
                    <Button
                      sx={{
                        color: '#166767',
                        display: 'flex',
                        alignItems: 'center',
                        textTransform: 'capitalize',
                        textAlign: 'center',
                      }}
                      onClick={() => navigate(link.route)}
                    >
                      <span style={{ paddingRight: '5px', fontSize: '9px' }}>
                        {link.icon}
                      </span>
                      <p style={{ fontSize: '15px', lineHeight: '15px' }}>
                        {' '}
                        {link.name}
                      </p>
                    </Button>
                  </MenuItem>
                ))}
                <MenuItem onClick={handleCloseUserMenu}>
                  <Button
                    sx={{
                      color: '#166767',
                      display: 'flex',
                      alignItems: 'center',
                      textTransform: 'capitalize',
                      textAlign: 'center',
                    }}
                    onClick={() => {
                      dispatch(logout());
                    }}
                  >
                    <span style={{ paddingRight: '5px', fontSize: '9px' }}>
                      <ExitToAppIcon />
                    </span>
                    <p style={{ fontSize: '15px', lineHeight: '15px' }}>
                      Выход
                    </p>
                  </Button>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {modal && <SupportModal remove={modal} />}
    </div>
  );
};
export default AppToolBar;