import {put, takeEvery} from 'redux-saga/effects';
import {
    addRoutesStopFailure,
    addRoutesStopSuccess,
    fetchRoutesStopsRequest,
    fetchRoutesStopsFailure,
    fetchRoutesStopsSuccess,
    addRoutesStopRequest,
    fetchRoutesGroupsRequest,
    fetchRoutesGroupsSuccess,
    fetchRoutesGroupsFailure,
    fetchRoutesUnitsSuccess,
    fetchRoutesUnitsFailure,
    fetchRoutesUnitsRequest,
    fetchSingleRoutesSuccess,
    fetchSingleRoutesFailure,
    fetchSingleRoutesRequest,
    fetchAssignUnitsSuccess,
    fetchAssignUnitsRequest,
    assignUnitsToRouteSuccess,
    setDeletePopup,
    routesGroupsAndUnitsClean,
    saveUpdateTime,
    addNewRouteRequest,
    postNewRouteRequest,
    cleanSelectedStop, putRouteRequest, putRouteStops,
} from '../actions/RoutesActions/routesActions';
import axiosApi from '../../axiosApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiURL } from '../../config';
import { toast } from 'react-toastify';
import { fetchStopsSuccess } from '../actions/stopsActions';
import { getCookie } from 'react-use-cookie';
import { loginUserFailure, loginUserSuccess } from '../actions/usersActions';
import History from '../../History';
import { dashboard, routes } from '../../paths';
const csrfToken = getCookie('csrftoken');

export function* postNewRouteSaga({ payload: route }) {
  try {
      console.log("data", JSON.stringify(route))
    const response = yield axiosApi.post('/route/', route);
    History.push(routes);
    toast.success('Успешно добавленено');
  } catch (e) {
    toast.error('Не удалось загрузить');
  }
}

export function* fetchRoutesSagas() {
  try {
    const response = yield axiosApi.get('/routes/');
    yield put(fetchRoutesStopsSuccess(response.data));
    // console.log(response.data)
  } catch (e) {
    console.log('Не удалось загрузить');
    yield put(fetchRoutesStopsFailure());
  }
}

export function* putRoutesSagas({ payload }) {
    try {
        console.log("DATA", JSON.stringify(payload.data))
        const response = yield axiosApi.put(`/route/${payload.id}/`, payload.data );
        yield put(routesGroupsAndUnitsClean())
        yield put(fetchSingleRoutesSuccess(response.data))
        toast.success('Маршрут изменен!');
    } catch (e) {
        toast.error('Не удалось загрузить');
        yield put(addRoutesStopFailure());
    }
}

export function* AddRoutesStopsSagas({ payload }) {
  try {
    const response = yield axiosApi.post('/routes/', payload);
    console.log('yep', payload)
    // yield put(addRoutesStopSuccess());
  } catch (e) {
    console.log('Не удалось добавить');
    yield put(addRoutesStopFailure());
  }
}

export function* fetchRoutesGroupsSagas() {
  try {
    const response = yield axiosApi.get('/groups/');
    yield put(fetchRoutesGroupsSuccess(response.data));
  } catch (e) {
    console.log('Не удалось добавить');
    yield put(fetchRoutesGroupsFailure());
  }
}

export function* fetchRoutesUnitsSagas() {
  try {
    const response = yield axiosApi.get('/units/');
    yield put(fetchRoutesUnitsSuccess(response.data));
  } catch (e) {
    console.log('Не удалось добавить');
    yield put(fetchRoutesUnitsFailure());
  }
}

export const deleteRouteById = createAsyncThunk(
  'get/deleteRoutes',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosApi.delete(`/route/${id}/`);
        dispatch(fetchStopsSuccess(response.data));
        dispatch(setDeletePopup())
        toast.success('Успешно удалено');
    } catch (error) {
      toast.error('Не удалось загрузить');
    }
  }
);

export const deleteRoute = createAsyncThunk(
  'get/deleteRoutesID',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosApi.delete(`/route/${id}/`);
      dispatch(fetchStopsSuccess(response.data));
        History.push(routes);
        // dispatch(setDeletePopup())
        toast.success('Успешно удалено');
    } catch (error) {
      toast.error('Не удалось загрузить');
    }
  }
);

// export const addNewRoute = createAsyncThunk(
//   'get/addRoutes',
//   async (id, { rejectWithValue, dispatch }) => {
//     try {
//       const response = await axiosApi.get(`route/${id}/`);
//       dispatch(saveUpdateTime(response.data));
//       dispatch(fetchSingleRoutesSuccess(response.data));
//     } catch (error) {
//       toast.error('Не удалось загрузить');
//     }
//   }
// );

export const fetchSingleRoutesThunk = createAsyncThunk(
  'get/fetchRoutes',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosApi.get(`route/${id}/`);
      dispatch(fetchSingleRoutesSuccess(response.data));
    } catch (error) {
      toast.error('Не удалось загрузить');
    }
  }
);

export const assignUnitsToRoute = createAsyncThunk(
  'search/fetchMaterial',
  async ({ route, unit, group }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosApi.post(`/routes/assign/`, {
        ids: route,
        u: unit,
        ug: group,
      });
      dispatch(routesGroupsAndUnitsClean());
      toast.success('Обьекты добавлены!');
    } catch (error) {
      toast.error('Не удалось загрузить');
    }
  }
);

export function* updateRoutesStop({ payload }) {
    try {
        // console.log(JSON.stringify(payload.data))
        // const response = yield axiosApi.put(`route/${payload.id}/`,  payload.data );
        // yield put(());
        toast.success('Маршрут изменен!');
    } catch (e) {
        toast.error('Не удалось загрузить');
        yield put(addRoutesStopFailure());
    }
}

// export const updateRoutesStop = createAsyncThunk(
//   'put/updateRoutesStops',
//   async ({ id, data }, { rejectWithValue, dispatch }) => {
//
//     try {
//       alert('u', data);
//       // const response = await axiosApi.put(`route/${id}/`, { data });
//       // dispatch(fetchSingleRoutesSuccess(response.data));
//       // dispatch(cleanSelectedStop());
//       toast.success('Маршрут изменен!');
//     } catch (error) {
//       toast.error('Не удалось загрузить ');
//     }
//   }
// );

export const updateTimeTable = createAsyncThunk(
    'put/updateTimeTable',
    async ({data,id}, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosApi.put(`route/${id}/`, {data});
            toast.success('Обьекты добавлены!');
        } catch (error) {
            toast.error('Не удалось загрузить ');
        }
    }
);

export const putTurnRoute = createAsyncThunk(
    'search/fetchMaterial',
    async ({id, active}, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosApi.put(`routes/status/`, {
                "ids": [id],
                "a": active
            })
            toast.success('Обьект изменён!');
        } catch (error) {
            toast.error('Не удалось загрузить');
        }
    }
);


const routesSaga = [
    takeEvery(fetchRoutesStopsRequest, fetchRoutesSagas),
    takeEvery(fetchRoutesUnitsRequest, fetchRoutesUnitsSagas),
    takeEvery(fetchRoutesGroupsRequest, fetchRoutesGroupsSagas),
    takeEvery(addRoutesStopRequest, AddRoutesStopsSagas),
    takeEvery(postNewRouteRequest, postNewRouteSaga),
    takeEvery(putRouteRequest, putRoutesSagas),
    takeEvery(putRouteStops, updateRoutesStop)
];

export default routesSaga;


