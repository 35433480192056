import {loginUser, loginUserFailure, loginUserSuccess, logout,} from "../actions/usersActions";
import axiosApi from "../../axiosApi";
import {toast} from "react-toastify";
import {put, takeEvery} from "redux-saga/effects";
import {dashboard, login} from "../../paths";
import History from '../../History';
import {getCookie} from "react-use-cookie";

const csrfToken = getCookie('csrftoken');

export function* loginUserSaga({payload:user}) {
    try {
        const response = yield axiosApi.post('/api/login/', user);
        yield put(loginUserSuccess(response.data));
        History.push(dashboard);
        toast.success('Вы авторизированы!');
    } catch (e) {
        if(e.response.status === 401){
            yield put(loginUserFailure("Неправильные данные"));
        }
        // toast.error(e.response.data.global);
        // yield put(loginUserFailure(e.response.data));
    }
}

export function* logoutUserSaga() {
    try {
        // yield axiosApi.post('/api/logout/', {}, {headers: {'X-CSRF-Token': csrfToken}});
        yield axiosApi.post('/api/logout/', {});
        document.cookie="csrftoken=; expires=Thu, 01 Jan 1970 00:00:01 GMT;"
        History.push(login)
    } catch (e) {
            yield put(loginUserFailure({message: "No internet connection"}));
            toast.error("Произошла ошибка")
    }
}

const usersSaga = [
    takeEvery(loginUser, loginUserSaga),
    takeEvery(logout, logoutUserSaga),
];

export default usersSaga;