import routesObjectSlice from "../../slices/Routes/RoutesObjectSlice";

export const {
    setIsCheckOBj,
    clearIsCheckObj,
    setIsCheck,
    clearIsCheck,
    setTimeTable,
    assignCheckUnit,
    clearCheckUnit,
    setActiveButton,
    cleanCheck,
    uncheckUnit,
    setNameUnit,
    cleanNameUnit
} = routesObjectSlice.actions