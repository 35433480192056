import {put, select, takeEvery} from 'redux-saga/effects';
import axiosApi from "../../axiosApi";
import {
    addStopFailure,
    addStopRequest,
    addStopSuccess,
    deleteSelectedStopFailure,
    deleteSelectedStopRequest,
    deleteSelectedStopSuccess,
    deleteStopFailure,
    deleteStopRequest,
    deleteStopSuccess,
    editStopFailure,
    editStopRequest,
    editStopSuccess,
    fetchAllStopsCountRequest,
    fetchAllStopsCountSuccess,
    fetchSingleStopRequest,
    fetchSingleStopSuccess,
    fetchStopInRoutesRequest,
    fetchStopInRoutesSuccess,
    fetchStopsFailure,
    fetchStopsForBus,
    fetchStopsForBusRequest,
    fetchStopsForTaxi,
    fetchStopsForTaxiRequest,
    fetchStopsForTrolleybus,
    fetchStopsForTrolleybusRequest,
    fetchStopsRequest,
    fetchStopsSuccess,
    fetchUnknownStops,
    fetchUnknownStopsRequest
} from "../actions/stopsActions";
import {toast} from "react-toastify";
import History from "../../History";
import {busStops} from "../../paths";


export function* fetchStopsSagas({ payload }) {
  try {
    if (payload) {
      const response = yield axiosApi.get('/stops/' + payload);
      yield put(fetchStopsSuccess(response.data));
      console.log(response.data);
    } else {
      const response = yield axiosApi.get('/stops');
      yield put(fetchStopsSuccess(response.data));
    }
  } catch (e) {
    toast.error('Не удалось загрузить');
    yield put(fetchStopsFailure());
  }
}

export function* fetchSingleStopSaga({ payload }) {
  try {
    const response = yield axiosApi.get('/stop/' + payload);
    yield put(fetchSingleStopSuccess(response.data));
  } catch (e) {
    toast.error('Ошибка при загружении остановки');
  }
}

export function* fetchAllStopsCountSaga({ payload }) {
  try {
    const response = yield axiosApi.get('/stops' + payload);
    yield put(fetchAllStopsCountSuccess(response.data));
  } catch (e) {
    toast.error('Не удалось загрузить');
  }
}

export function* fetchStopsForBusSaga({ payload }) {
  try {
    const response = yield axiosApi.get('/stops' + payload);
    yield put(fetchStopsForBus(response.data));
  } catch (e) {
    toast.error('Не удалось загрузить');
  }
}

export function* fetchStopsForTrolleybusSaga({ payload }) {
  try {
    const response = yield axiosApi.get('/stops' + payload);
    yield put(fetchStopsForTrolleybus(response.data));
  } catch (e) {
    toast.error('Не удалось загрузить');
  }
}

export function* fetchStopsForTaxiSaga({ payload }) {
  try {
    const response = yield axiosApi.get('/stops' + payload);
    yield put(fetchStopsForTaxi(response.data));
  } catch (e) {
    toast.error('Не удалось загрузить');
  }
}

export function* fetchUnknownStopsSaga({ payload }) {
  try {
    const response = yield axiosApi.get('/stops' + payload);
    yield put(fetchUnknownStops(response.data));
  } catch (e) {
    toast.error('Не удалось загрузить');
  }
}

export function* fetchRoutesForStopSaga({ payload: id }) {
  try {
    const response = yield axiosApi.get('/route/via/' + id);
    yield put(fetchStopInRoutesSuccess(response.data));
  } catch (e) {
    toast.error('Не удалось загрузить');
  }
}

export function* AddStopsSagas({ payload }) {
  const state = yield select();
  const type = state.stops.transportTypes;

  try {
    const response = yield axiosApi.post('/stop/', payload);
    yield put(addStopSuccess());
    if (type === 'Автобусы') {
      yield put(fetchStopsRequest('?tp=1'));
    } else if (type === 'Троллейбусы') {
      yield put(fetchStopsRequest('?tp=2'));
    } else if (type === 'Маршрутные такси') {
      yield put(fetchStopsRequest('?tp=3'));
    } else if (type === 'Без типа') {
      yield put(fetchStopsRequest('?tp=0'));
    } else {
      yield put(fetchStopsRequest());
    }
    toast.success(`${payload.n} - остановка добавлена`);
  } catch (e) {
    yield put(addStopFailure());
  }
}

export function* deleteStopSaga({ payload: id }) {
  const state = yield select();
  const type = state.stops.transportTypes;
  try {
    yield axiosApi.delete(`/stop/${id}`);
    yield put(deleteStopSuccess(id));

    if (type === 'Автобусы') {
      yield put(fetchStopsRequest('?tp=1'));
    } else if (type === 'Троллейбусы') {
      yield put(fetchStopsRequest('?tp=2'));
    } else if (type === 'Маршрутные такси') {
      yield put(fetchStopsRequest('?tp=3'));
    } else if (type === 'Без типа') {
      yield put(fetchStopsRequest('?tp=0'));
    } else {
      yield put(fetchStopsRequest());
    }
    toast.success('Остановка удалена!');
  } catch (e) {
    yield put(deleteStopFailure());
  }
}

function* deleteSelectedStopSaga({ payload: id }) {
  const state = yield select();
  const type = state.stops.transportTypes;
  try {
    yield axiosApi.delete(`/stops/`, { data: { ids: id } });
    yield put(deleteSelectedStopSuccess(id));
    if (type === 'Автобусы') {
      yield put(fetchStopsRequest('?tp=1'));
    } else if (type === 'Троллейбусы') {
      yield put(fetchStopsRequest('?tp=2'));
    } else if (type === 'Маршрутные такси') {
      yield put(fetchStopsRequest('?tp=3'));
    } else if (type === 'Без типа') {
      yield put(fetchStopsRequest('?tp=0'));
    } else {
      yield put(fetchStopsRequest());
    }
    toast.success('Выбранные остановки удалены!');
  } catch (e) {
    yield put(deleteSelectedStopFailure());
    console.log(e);
  }
}



export function* editStopSaga({payload}) {
    const state = yield select();
    const type = state.stops.transportTypes;
    try {
        yield axiosApi.put(`/stop/${payload.id}`, payload.obj );
        yield put(editStopSuccess());
        if(type === "Автобусы"){
            yield put(fetchStopsRequest("?tp=1"))
        }else if(type === "Троллейбусы"){
            yield put(fetchStopsRequest("?tp=2"))
        }else if(type === "Маршрутные такси"){
            yield put(fetchStopsRequest("?tp=3"))
        }else if(type === "Без типа") {
            yield put(fetchStopsRequest("?tp=0"))
        }else{
            yield put(fetchStopsRequest())
        }
        toast.success('Остановка обновлена!');
        History.push(busStops);
    } catch (e) {
        yield put(editStopFailure());
    }
}

const stopsSaga = [
    takeEvery(fetchStopsRequest, fetchStopsSagas),
    takeEvery(fetchStopsForBusRequest, fetchStopsForBusSaga),
    takeEvery(fetchAllStopsCountRequest, fetchAllStopsCountSaga),
    takeEvery(fetchStopsForTrolleybusRequest, fetchStopsForTrolleybusSaga),
    takeEvery(fetchStopsForTaxiRequest, fetchStopsForTaxiSaga),
    takeEvery(fetchUnknownStopsRequest, fetchUnknownStopsSaga),
    takeEvery(addStopRequest, AddStopsSagas),
    takeEvery(fetchSingleStopRequest, fetchSingleStopSaga),
    takeEvery(deleteStopRequest, deleteStopSaga),
    takeEvery(editStopRequest, editStopSaga),
    takeEvery(deleteSelectedStopRequest, deleteSelectedStopSaga),
    takeEvery(fetchStopInRoutesRequest, fetchRoutesForStopSaga),
];


export default stopsSaga;